import React, { useState, useRef } from 'react';
import { OrangeButton, Input } from 'components';
import { VideoPlayer } from 'components';
import emailjs from '@emailjs/browser';
import { emailJsPublicKey, emailJsServiceId, emailJsTemplateIdOnboarding } from 'helpers/constants';
import BoxContentImg from 'assets/images/oculus-quest-2-box-content.png';

const ONBOARD_PAGE_VIDEO_DE = "https://player.vimeo.com/video/924399071?h=29ff664462&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

export const OnboardAdvancedDe = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const currentPath = window.location.pathname;

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(emailJsServiceId, emailJsTemplateIdOnboarding, form.current, {
        publicKey: emailJsPublicKey,
      })
      .then(
        () => {
          form.current.reset();
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log('FAILED...', error.text);
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='flex flex-col bg-dark-blue p-40 lg:p-20 sm:!p-0 sm:!py-10 overflow-hidden'>
      <section className='flex flex-col items-center text-center gap-y-10 lg:gap-y-8 sm:!gap-y-6 sm:px-10'>
        <p className='text-white text-3xl lg:text-xl sm:!text-base'>Liebe Trainingsteilnehmer*innen!</p>
        <p className='text-white text-3xl lg:text-xl sm:!text-base'>Es ist endlich so weit. Sie können jetzt zum ersten Mal Ihr Virtual Reality Headset aufsetzen und sich auf das Training vorbereiten. </p>
        <p className='text-white text-3xl lg:text-xl sm:!text-base'>Für diese Vorbereitung werden Sie ca. {currentPath.includes('axr') ? '20' : '10'} Minuten benötigen.</p>
        <p className='text-orange uppercase text-3xl  font-semibold lg:text-xl sm:!text-base'>BITTE VERBINDEN SIE DAS HEADSET NICHT MIT DEM W-LAN</p>
      </section>
      <section className='flex flex-col'>
        <div className='flex flex-col mt-32 gap-y-10 lg:gap-y-8 lg:mt-20 sm:!gap-y-6 sm:px-10 sm:!mt-16'>
          <p className='text-orange text-3xl font-semibold lg:text-xl sm:!text-base'>Sicherheit und Gesundheit</p>
          <p className='text-white text-2xl text-justify font-normal lg:text-lg sm:!text-base'>
            Das VR-Headset darf nur in Innenräumen und von Personen ab 13 Jahren verwendet werden. Nach einer vollen Stunde Benutzung wird empfohlen,
            das Headset für mindestens 15 Minuten abzunehmen.
            Wir haben ein strenges Hygienekonzept, das den Covid-19-Vorschriften entspricht. Die VR-Ausrüstung
            wird von uns desinfiziert, bevor sie verschickt wird.</p>
          <p className='text-white text-2xl text-justify font-normal lg:text-lg sm:!text-base'>Haben Sie Höhenangst? Schwere Reisekrankheit? Davon ist schätzungsweise 1 % der Weltbevölkerung betroffen und dies kann die VR-Nutzung beeinträchtigen. Unsere VR-Erlebnisse sind so gestaltet, dass sie solche Reaktionen NICHT auslösen (keine plötzlichen Bewegungen usw.). Wenn Sie sich körperlich unwohl fühlen, können Sie das Headset jederzeit abnehmen.</p>
        </div>
        <div className='flex flex-col mt-32 gap-y-10 lg:gap-y-8 lg:mt-20 sm:!gap-y-6 sm:px-10 sm:!mt-16'>
          <p className='text-orange text-3xl font-semibold lg:text-xl sm:!text-base'>VR und Training – Einführung</p>
          <p className='text-white text-2xl text-justify font-normal lg:text-lg sm:!text-base'>Sie begeben sich in eine interaktive Erfahrung. Sie benutzen ein VR-Headset und zwei Controller, um sich in der virtuellen Welt zu bewegen. Mit dem Headset können Sie die virtuelle Welt sehen und hören, mit den Controllern können Sie mit ihr interagieren.
            Wir glauben, dass VR-Training eine private Erfahrung ist. Nachdem Sie sich an die Technologie gewöhnt haben, sind Sie beim
            Training normalerweise allein im Raum. Wir stehen Ihnen online zur Seite, wenn Sie uns brauchen.</p>
          <p className='text-white text-2xl text-justify font-normal lg:text-lg sm:!text-base'>Im Allgemeinen ist der Raum in der virtuellen Realität größer als der Raum, der Ihnen in Ihrem Zimmer zur Verfügung steht. Um sich in dem viel größeren VR-Raum zu bewegen, können Sie die Controller zum “Teleportieren” verwenden. Wie man das macht, wird Ihnen direkt in der VR-Erfahrung gezeigt.</p>
        </div>
        <div className='flex flex-col mt-32 gap-y-10 lg:gap-y-8 lg:mt-20 sm:!gap-y-6 sm:!mt-16'>
          <p className='text-orange text-3xl font-semibold lg:text-xl sm:px-10 sm:!text-base'>Inhalt des Pakets</p>
          <p className='text-white text-2xl font-normal lg:text-lg sm:px-10 sm:!text-base'>Wenn Sie das Paket öffnen, sollten Sie den folgenden Inhalt vorfinden:</p>
          <ul className='list-disc list-inside ml-3 gap-y-1.5 flex flex-col sm:px-8'>
            <li className='text-white text-2xl font-normal lg:text-lg sm:!text-base'>Das Headset</li>
            <li className='text-white text-2xl font-normal lg:text-lg sm:!text-base'>Zwei Controller</li>
            <li className='text-white text-2xl font-normal lg:text-lg sm:!text-base'>Ein Ladegerät</li>
            <li className='text-white text-2xl font-normal lg:text-lg sm:!text-base'>Ein Ladekabel (USB-C auf USB-C)</li>
          </ul>
          <div className='w-full my-32 px-20 lg:my-20 lg:px-0 sm:!my-16'>
            <img className='w-full h-auto' src={BoxContentImg} alt='box content' />
          </div>
        </div>
      </section>
      <section className='flex flex-col'>
        <p className='text-3xl text-white text-center lg:text-xl sm:!text-base sm:px-10'>Hier finden Sie ein Onboarding-Video mit allen Informationen, die Sie zur Vorbereitung benötigen</p>
        <div className='w-full mt-32 px-20 lg:mt-20 lg:px-0 sm:!mt-16'>
          <VideoPlayer className='w-full h-auto pt-[56.3%] md:w-full md:pt-[56.3%] md:translate-x-0 md:opacity-100' src={ONBOARD_PAGE_VIDEO_DE} />
        </div>
      </section>
      {(currentPath.includes('onboard_advance_de') || currentPath.includes('onboard_axr_de')) && (
        <form ref={form} onSubmit={sendEmail} className='email-form self-center mt-56 flex flex-col gap-y-14 w-full max-w-[40rem] lg:px-20 md:mt-20 sm:min-w-full lg:!gap-y-8 sm:!px-10 sm:items-center'>
          <Input name="user_name" className='sm:items-center' labelColor="text-white sm:!mb-6" label='Ihr Name' type='text' isActive />
          <p className='font-bold text-xl ml-2 xl:text-base xl:ml-1.5 text-white sm:text-center'>Ich habe das VR-Headset erhalten</p>
          <div className='flex gap-x-10'>
            <Input name="vr_recieved" labelColor="text-white" label='Ja' type='radio' isActive />
            <Input name="vr_recieved" labelColor="text-white" label='Nein' type='radio' isActive />
          </div>
          <p className='font-bold text-xl ml-2 xl:text-base xl:ml-1.5 text-white sm:text-center'>Ich habe mir das VR-Vorbereitungsvideo angesehen und alle beschriebenen Schritte durchgeführt</p>
          <div className='flex gap-x-10'>
            <Input name="video_watched" labelColor="text-white" label='Ja' type='radio' isActive />
            <Input name="video_watched" labelColor="text-white" label='Nein' type='radio' isActive />
          </div>
          <p className='font-bold text-xl ml-2 xl:text-base xl:ml-1.5 text-white sm:text-center'>Ich brauche technische Unterstützung</p>
          <div className='flex gap-x-10'>
            <Input name="need_support" labelColor="text-white" label='Ja' type='radio' isActive />
            <Input name="need_support" labelColor="text-white" label='Nein' type='radio' isActive />
          </div>

          <OrangeButton className={` mt-4 flex  items-center justify-center duration-300 delay-1000 translate-x-0 opacity-100 hover:delay-0`}>
            {loading ? "SENDEN" : "EINREICHEN"}
            {loading && <svg aria-hidden="true" className="w-6 h-6 animate-spin ml-2" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#173658" />
            </svg>}
          </OrangeButton>
        </form>
      )}
    </div>
  );
};
