import React, { useEffect, useState, useRef } from 'react';
import { OrangeButton } from 'components';
import SectionBg from 'assets/images/conversation-bright.jpg';
import { isBlockVisibleOnScreen } from "helpers/utils";
import { HashLink } from 'react-router-hash-link';

export const HomeSection4 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActive, [0.5, 1]);
  }, []);

  return (
    <div ref={sectionRef} className='flex relative pt-[56.3%] overflow-hidden md:pt-[172%]'>
      <span className='absolute top-0 left-0 w-full h-full bg-blue-gradient-to-right z-10 pointer-events-none	'></span>
      <img className='absolute top-0 left-0 w-full h-full md:object-cover md:object-[80%]' src={SectionBg} alt="vr background" loading="lazy" />
      <div className='max-w-[45rem] absolute top-1/2 -translate-y-1/2 left-28 z-20 xl:max-w-[27rem] xl:left-16 md:text-right md:left-8 md:right-8 md:!top-[unset] md:bottom-10 md:translate-y-[unset]'>
        <p className={`text-5xl font-semibold text-white duration-300 xl:text-3xl ${active ? ' translate-x-0 opacity-100' : 'translate-x-1/4 opacity-0'}`}>Give your team a jumpstart to success</p>
        <p className={`text-2xl font-semibold text-white my-14 duration-300 xl:text-lg xl:my-8 md:text-base md:!mt-6 md:!mb-4 ${active ? 'delay-100 translate-x-0  opacity-100' : 'translate-x-1/4 opacity-0'}`}>We use Virtual Reality to create a safe arena that allows leaders to examine, play through, and practice situations as they would when they face real challenges.</p>
        <HashLink to="/our-solutions#leadership-training">
          <OrangeButton className={active ? 'duration-300 delay-300 translate-x-0 opacity-100 hover:delay-0' : 'duration-300 translate-x-1/4 opacity-0'}>Our solutions</OrangeButton>
        </HashLink>
      </div>
    </div>
  );
};
