import { useEffect, useState } from 'react';

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getInitialDimensions);

  function getInitialDimensions() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getInitialDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowDimensions;
}

export function useIsMobile() {
  const { width } = useWindowDimensions();

  return width < 550;
}

export const useScrollY = () => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollY;
};
