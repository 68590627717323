import React, { useEffect, useState, useRef, useCallback } from 'react';
import Mihai from 'assets/images/Mihai.png';
import Damir from 'assets/images/damir.png';
import Daniel from 'assets/images/daniel.png';
import { useWindowDimensions } from 'hooks';

export const AboutSection3 = () => {
  const mihaiRef = useRef();
  const damirRef = useRef();
  const danielRef = useRef();
  const [activeMihai, setActiveMihai] = useState(false);
  const [activeDamir, setActiveDamir] = useState(false);
  const [activeDaniel, setActiveDaniel] = useState(false);
  const { width } = useWindowDimensions();

  const handleScroll = useCallback(() => {
    if (width > 900) {
      if (mihaiRef.current.getBoundingClientRect().y < window.innerHeight / 2 && !activeMihai) setActiveMihai(true);
      if (damirRef.current.getBoundingClientRect().y < window.innerHeight / 2 && !activeDamir) setActiveDamir(true);
      if (danielRef.current.getBoundingClientRect().y < window.innerHeight / 2 && !activeDaniel) setActiveDaniel(true);
    }
  }, [activeDamir, activeDaniel, activeMihai, width]);

  useEffect(() => {
    width > 900 && window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, width]);

  return (
    <section id='meet-the-founders' className='flex flex-col items-center relative overflow-hidden pt-32 px-20 md:px-10 md:pt-20'>
      <div ref={mihaiRef} className='flex items-center mb-12 md:flex-col'>
        <div className={`relative w-[67%] pt-[67%] z-20 md:w-full md:pt-[100%]`}>
          <img className='absolute top-0 left-0 w-full h-full' src={Mihai} />
        </div>
        <div className={`flex flex-col flex-1 pl-6 overflow-hidden mix-blend-difference md:items-center md:text-center md:mt-10 md:pl-0 md:mix-blend-normal`}>
          <p className={`text-4xl text-creme font-bold md:opacity-100 md:translate-x-0 md:!text-white md:text-2xl ${activeMihai ? 'opacity-100 duration-500 translate-x-0' : 'opacity-0 -translate-x-full duration-100 '}`}>MIHAI STREZA</p>
          <p className={`text-4xl text-creme font-bold md:opacity-100 md:translate-x-0 md:!text-white md:text-2xl md:delay-0 ${activeMihai ? 'opacity-100 duration-500 delay-150  translate-x-0' : 'opacity-0 -translate-x-full duration-100 '}`}>CEO & Founder</p>
          <p className={`text-base text-creme mt-6 text-justify md:opacity-100 md:translate-x-0 md:!text-white md:text-center md:delay-0 ${activeMihai ? 'opacity-100 duration-500 delay-300  translate-x-0' : 'opacity-0 -translate-x-full duration-100 '}`}>Mihai Streza, CEO and founder of wondder launched the company in 2019 with the aim of building a portfolio of VR experiences for HR and B2B training.</p>
        </div>
      </div>
      <div ref={damirRef} className='flex items-center mb-12 md:flex-col-reverse'>
        <div className={`flex flex-col flex-1 pr-6 overflow-hidden md:items-center md:text-center md:mt-10 md:pr-0`}>
          <p className={`text-4xl text-white font-bold text-right md:opacity-100 md:translate-x-0 md:text-white md:text-2xl ${activeDamir ? 'opacity-100 duration-500 translate-x-0' : 'opacity-0 translate-x-full duration-100 '}`}>DAMIR SIMOVSKI</p>
          <p className={`text-4xl text-white font-bold text-right md:opacity-100 md:translate-x-0 md:text-white md:text-2xl md:delay-0 ${activeDamir ? 'opacity-100 duration-500 delay-150  translate-x-0' : 'opacity-0 translate-x-full duration-100 '}`}>CTO & Co-Founder</p>
          <p className={`text-base text-white mt-6 text-right md:opacity-100 md:translate-x-0 md:text-white md:text-center md:delay-0 ${activeDamir ? 'opacity-100 duration-500 delay-300  translate-x-0' : 'opacity-0 translate-x-full duration-100 '}`}>As Co-founder and chief technology officer, Damir Simovski is responsible for the strategic planning, product development and IT infrastructure at Wondder.</p>
        </div>
        <div className={`relative w-[67%] pt-[67%] z-20 md:w-full md:pt-[100%]`}>
          <img className='absolute top-0 left-0 w-full h-full' src={Damir} />
        </div>
      </div>
      <div ref={danielRef} className='flex items-center mb-12 md:flex-col'>
        <div className={`relative w-[67%] pt-[67%] z-20 md:w-full md:pt-[100%]`}>
          <img className='absolute top-0 left-0 w-full h-full' src={Daniel} />
        </div>
        <div className={`flex flex-col flex-1 pl-6 overflow-hidden mix-blend-difference md:items-center md:text-center md:mt-10 md:pl-0 md:mix-blend-normal`}>
          <p className={`text-4xl text-creme font-bold md:opacity-100 md:translate-x-0 md:text-white md:text-2xl ${activeDaniel ? 'opacity-100 duration-500 translate-x-0' : 'opacity-0 -translate-x-full duration-100 '}`}>DANIEL HOULDEN</p>
          <p className={`text-4xl text-creme font-bold md:opacity-100 md:translate-x-0 md:text-white md:text-2xl md:delay-0 ${activeDaniel ? 'opacity-100 duration-500 delay-150  translate-x-0' : 'opacity-0 -translate-x-full duration-100 '}`}>Co-Founder</p>
          <p className={`text-base text-creme mt-6 text-justify md:opacity-100  md:translate-x-0 md:text-white md:text-center md:delay-0 ${activeDaniel ? 'opacity-100 duration-500 delay-300  translate-x-0' : 'opacity-0 -translate-x-full duration-100 '}`}>Daniel Houlden can look back on over 20 years of sales expertise with a focus on new business development. He is responsible for the development and implementation of the sales and marketing strategy at Wondder.</p>
        </div>
      </div>
    </section>
  );
};
