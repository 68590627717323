/* eslint-disable max-len */
import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col items-center text-sm text-white font-bold  bg-dark-blue min-h-[100vh] py-[9rem] px-20 sm:px-10 sm:py-12">
      <section className='flex flex-col max-w-[68rem] gap-y-4'>
        <p>Name and address of the controller</p>

        <p>The controller in terms of the GDPR and the new Federal Data Protection Act (BDSG) is the</p>

        <p>Press and Information Office of the Federal Government&#8232;<br />
          State Secretary Steffen Hebestreit&#8232;Dorotheenstra&szlig;e 84&#8232;<br />
          10117 Berlin&#8232;<br />
          Telephone: +49 (0)30 18 272-0&#8232;<br />
          Fax: +49 (0)30 18-272-2555&#8232;<br />
          <a href="mailto:internetpost@bundesregierung.de" className='underline'>internetpost@bundesregierung.de</a></p>

        <p>Technical implementation<br /> Aktiengesellschaft f&uuml;r digitale Kommunikation&#8232;<br />K&ouml;penicker Stra&szlig;e 9&#8232;<br />10997 Berlin&#8232;<br />Internet:&#160;<a className='underline' href='www.init.de'>www.init.de</a></p>

        <p>I. Name and address of the data privacy officer</p>

        <p>Wolfgang Schlesener&#8232;<br />Press and Information Office of the Federal Government - data privacy officer -&#8232;Dorotheenstra&szlig;e 84&#8232;<br />10117 Berlin&#8232;<br />Telephone: 030 18 272-0&#8232;<br />Fax: 030 18-272-2555&#8232;<br /><a href="mailto:datenschutzbeauftragte@bpa.bund.de" className='underline'>datenschutzbeauftragte@bpa.bund.de</a></p>

        <p>II. General information on data processing</p>

        <p>1. Scope of processing personal data<br />
          We process personal data of our users only to the extent necessary for the presentation and optimisation of a functional website as well as our content and services.<br />
          The processing of personal data of our users normally takes place only with their consent. An exception applies in those cases where the data processing is permitted by law.</p>

        <p>2. Legal basis for processing personal data<br />
          This website presented by the Press and Information Office of the Federal Government (Federal Press Office) and its individual components are part of the public relations work of the Federal Press Office. The legal basis for processing personal data within the framework of this public relations work is Article 6 (1) point (e) of the EU General Data Protection Regulation (GDPR) in conjunction with Section 3 of the BDSG. Where we obtain consent to process personal data, the legal basis is provided by Article 6 (1) point (a) of the GDPR.<br />
          The legal basis for processing personal data which is required for the performance of a contract to which you are a party (e.g. ordering publications and newsletters) is Article 6 (1) point (b) of the GDPR. This also applies to pre-contractual measures.</p>

        <p>3. Data erasure and storage duration<br />
          Your personal data will be erased or blocked, as soon as the purpose for which it was stored no longer applies.</p>

        <p>III. Presentation of the website and creation of log files</p>

        <p>1. Description and scope of data processing<br />
          Each time our website is accessed, our system automatically collects data and information from the computer system of the computer accessing it.<br /> The following data is logged:<br />
          <ul className='list-disc	pl-5 mt-2'>
            <li>information about the browser type and version used</li>
            <li>the user&rsquo;s operating system<br /></li>
            <li>the user&rsquo;s IP address<br /></li>
            <li>This data is stored on the delivery servers for 90 days, to secure operations and protect against attacks (e.g. DDOS).<br /></li>
            <li>date and time of access including the URL accessed, HTTP method used and status code returned, along with the amount of data transmitted<br /></li>
            <li>HTTP origin request header sent by the client<br /></li>
            <li>websites from which the user&rsquo;s system accesses our website<br /></li>
            <li>saved as referrer</li>
          </ul>
        </p>

        <p>2. Legal basis for data processing</p>

        <p>The legal basis for the temporary storage of data and log files is Art. 6(1)(e) GDPR in conjunction with Section 3 BDSG (new).</p>

        <p>3. Purpose of data processing</p>

        <p>Temporary storage of the IP address by the system is necessary for the website to be delivered to the user&rsquo;s computer. For this purpose, the user&rsquo;s IP address must remain stored for the duration of the session.<br />
          The IP is stored on the delivery servers for 90 days, serving to secure operations and provide protection against attacks (e.g. DDOS).</p>

        <p>4. Duration of storage</p>

        <p>The log files are stored centrally and deleted after 90 days.</p>

        <p>5. Right to object and deletion</p>

        <p>The collection of data for the provision of the website and the storage of data in log files is absolutely necessary for the operation of the website. For this reason, it is not possible for users to object.</p>

        <p>IV. Use of cookies</p>

        <p>a) Description and scope of data processing<br />
          Technically necessary cookies are required to provide you with the basic functions of the website and, therefore, cannot be deactivated.<br />
          In detail, these cookies are as follows:&#160;&#8232;&#8232;CM_SESSIONID&#160;&ndash; CoreMedia Sessioncookie: This cookie is a session identifier and allows the server to allocate the right session to the user on reloading the website. Even if the website is opened in a new tab, the server can allocate the session number. This is a standard cookie of the product CoreMedia.&#160;</p>

        <p>cart&#160;- Shopping basket cookie: This cookie saves a session number, the publications selected and quantity chosen by the user. It is required to provide an existing shopping basket to an actual visitor on reloading the website. The cookie is only set once an item is put in the shopping basket.&#160;<br />
          cookie-allow-necessary&#160;&ndash; A cookie to save the setting &ldquo;Only necessary cookies&rdquo;. Standard setting on visiting the website.<br />
          cookie-banner&#160;&ndash; Cookie to hide the cookie banner. Prevents the banner being shown again after loading the website.<br />
          cookie-allow-tracking &ndash;&#160;The cookie stores the user&rsquo;s interaction with the banner.</p>

        <p>mtm_consent_removed&#160;&ndash; The cookie &ldquo;mtm consent removed&rdquo; is activated when the page is first accessed since the user has not agreed to tracking by the statistics tool Matomo when calling up the website. The cookie contains the information that tracking has not been agreed to.<br />
          mtm_consent&#160;&ndash; If the checkbox &ldquo;statistics&rdquo; in the cookie banner is selected, the cookie &ldquo;mtm_consent_removed&rdquo; is removed and replaced by the cookie &ldquo;mtm_consent&rdquo;. This cookie contains the information that the user has agreed to tracking. As soon as users deselect the cookie, the cookie &ldquo;mtm_consent&rdquo; is replaced again by &ldquo;mtm_consent_removed&rdquo;.</p>

        <p>INGRESSCOOKIE&#160;&ndash; Cookie for storing information in forms, for example in the contact form or when changes are made to the shopping basket.<br />
          enodia&#160;&ndash; The cookie is used for DDoS defence and therefore website security.<br />
          SERVERID&#160;- This cookie enables the website to load faster.</p>

        <p>b) Legal basis for data processing<br />
          The legal basis for processing personal data using cookies is Article 6 (1) point (e) of the GDPR in conjunction with Section 3 of the new BDSG and/or Article 6 (1) point (a) of the GDPR for cookies which require consent.</p>

        <p>c) Purpose of data processing</p>

        <p>Necessary cookies are required to provide you with the basic functions of the website<br />
          The user data collected by technically necessary cookies will not be used to create user profiles.</p>

        <p>d) Duration of storage and rights to objection and deletion</p>

        <p>Cookies are stored on the user&apos;s computer and transmitted to our site from there. Therefore, you as a user also have full control over the use of cookies. By changing the settings in your Internet browser, you can disable or restrict the transfer of cookies. Previously stored cookies can be deleted at any time. This can also be done automatically. Matomo is deactivated when you visit our website. Only after you have actively allowed it will your user behaviour be recorded anonymously.</p>

        <p>V. Web analytics by Matomo</p>

        <p>1. Scope of processing personal data</p>

        <p>On our website we use the open source software tool Matomo (formerly PIWIK) to analyse the surfing behaviour of our users. This is an open source web analysis tool. Matomo does not transfer any data to servers outside the control of the Federal Press Office. Matomo does not record any session data without your consent.</p>

        <p>Matomo uses cookies. These text files are saved on your computer and enable the Federal Press Office to analyse how its website is used. The information on usage collected by the cookie is transferred to the Federal Press Office server and saved there so that we can analyse user behaviour. For us, your IP address is an anonymous code. This means we have no technical means of identifying you as a registered user. You will remain anonymous as a user.</p>

        <p>The Federal Press Office regards this analysis as an integral part of its online service. Its aim is to consistently improve the website and align it more closely with user needs.</p>

        <p>If you consent to web analytics by Matomo, the following data is stored when individual pages of our website are accessed:</p>

        <p>(1) 2 Bytes of the IP address of the user&rsquo;s accessing system&#8232;(2) the website accessed&#8232;(3) the website from which the user reached the website accessed here (referrer)&#8232;(4) the other web pages visited from the main website accessed&#8232;(5) the time spent looking at the website&#8232;(6) the frequency with which the website is accessed.</p>

        <p>The analysis software runs exclusively on the servers of our website. Storage of the user&rsquo;s personal data only takes place there. The data is not passed on to third parties.</p>

        <p>You can decide here whether or not to allow a web analysis cookie to be stored in your browser to enable the Federal Press Office to gather and analyse statistical data.</p>

        <p>You can decide here whether or not to allow a web analysis cookie to be stored in your browser to enable the website operator to gather and analyse statistical data. If you decide against this, remove the tick from the box below to store the Matomo deactivation cookie in your browser.<br />
          &#160;Currently your visit to this website is being recorded by Matomo web analysis. If you do not wish your visit to be recorded in future, click here.</p>

        <p>2. Legal basis for processing personal data</p>

        <p>The legal basis for processing personal data of users is Article 6 (1) point (a) of the GDPR.</p>

        <p>3. Purpose of data processing</p>

        <p>The processing of personal data of users enables us to analyse the surfing behaviour of our users. By evaluating the collected data, we are able to generate information about the use of the individual components of our website. This helps us to continually improve our website and its user-friendliness. By anonymising the IP address, the interests of the users regarding protection of personal data are sufficiently taken into account.</p>

        <p>4. Duration of storage</p>

        <p>The data is deleted as soon as it is no longer needed for our record-keeping purposes. The generated statistics and underlying data are not deleted.</p>

        <p>5. Right to objection and deletion</p>

        <p>Cookies are stored on the user&apos;s computer and transmitted to our site from there. Therefore, you as a user also have full control over the use of cookies. By changing the settings in your Internet browser, you can disable or restrict the transfer of cookies. Previously stored cookies can be deleted at any time. This can also be done automatically. If cookies are disabled for our website, you might not be able to fully use all of the website&rsquo;s functions.</p>

        <p>Please see the following link for more information about the privacy settings of the Matomo software:&#160;https://matomo.org/docs/privacy/.<br />
          You are hereby reminded of your rights to correction, erasure and objection in terms of Articles 16, 17 and 21 GDPR; see Chapter XI for more details.</p>

        <p>VI. Newsletter</p>

        <p>1. Description and scope of data processing</p>

        <p>You have the opportunity to subscribe to free newsletters on our website. When you register to receive a newsletter, the data from the input form is sent to us. This is your e-mail address, together with your choice of the desired format.</p>

        <p>In addition, the following data is recorded when you subscribe:</p>

        <p>(1) the IP address of the accessing computer&#8232;(2) the date and time of registration.</p>

        <p>During the newsletter registration process, your consent is obtained for processing the data for this purpose, and you are referred to this privacy policy.</p>

        <p>There is no disclosure of data to third parties during the data processing required for dispatching newsletters. The data is used solely for sending the newsletter.</p>

        <p>2. Legal basis for data processing</p>

        <p>The legal basis for processing data after registration for the newsletter by the user, once their consent has been obtained, is Article 6 (1) point (a) of the GDPR. In addition, the newsletter subscription is based on a contractual relationship, so Article 6 (1) point (b) of the GDPR also applies.</p>

        <p>3. Purpose of data processing</p>

        <p>The collection of the user&rsquo;s e-mail address is for the purpose of delivering the newsletter.<br />
          The collection of other personal data in the context of the registration process is to prevent abuse of the services or the e-mail address.</p>

        <p>4. Duration of storage</p>

        <p>The data is deleted as soon as the purpose for which it was stored no longer applies. The e-mail address of the user is therefore stored as long as the newsletter subscription is active.<br />
          The newsletter is delivered based on registration by the user at the website.<br />
          The other personal data collected as part of the registration process is usually deleted after a period of seven days.</p>

        <p>5. Right to objection and deletion</p>

        <p>The user can cancel the subscription to the newsletter at any time. There is a corresponding link in every newsletter for this purpose.<br />
          This also enables a withdrawal of the consent to the storage of personal data collected during the registration process.<br />
          You can have the data stored about you modified at any time.</p>

        <p>VII. Contact form, e-mail contact and postal contact</p>

        <p>1. Description and scope of data processing</p>

        <p>There is a contact form on our website which can be used for contacting us online. If you make use of this opportunity, the data entered in the input form is sent to us and stored. This data is:</p>

        <p>subject&#8232;your message&#8232;title, first name and surname, e-mail address, street and house number, postal code, city.<br />
          At the time of sending the message, the following additional data is stored:</p>

        <p>(1) the IP address of the user<br />
          &#8232;(2) the date and time of registration.</p>

        <p>During the sending process, your consent is obtained for processing the data for this purpose, and you are referred to this privacy policy statement.<br />
          Alternatively, you can contact us via the e-mail address provided, or by post. In this case, the user&apos;s personal data transmitted together with the e-mail or letter is stored.</p>

        <p>In this regard, there is no disclosure of data to third parties. The data is used solely for processing the conversation.</p>

        <p>2. Legal basis for data processing</p>

        <p>The legal basis for processing data in the course of sending an e-mail is Article 6 (1) point (e) of the GDPR. If the aim of the e-mail contact is the conclusion of a contractual relationship, then Article 6 (1) point (b) of the GDPR also applies to the data processing.</p>

        <p>3. Purpose of data processing</p>

        <p>The processing of personal data from the input form is used solely for us to handle the contact request. The other personal data processed during the sending process is intended to prevent misuse of the contact form, and to ensure the security of our information technology systems.</p>

        <p>4. Duration of storage</p>

        <p>The data is deleted as soon as the purpose for which it was stored no longer applies. There is a retention period of up to three years for the personal data entered into the contact form, and the data sent by e-mail or post.<br />
          The other personal data collected as part of the sending process is deleted after a maximum period of seven days.</p>

        <p>5. Right to objection and deletion</p>

        <p>The user has the right to withdraw their consent to the processing of personal data at any time. If the user contacts us by e-mail, among other options, they can object to the storage of their personal data at any time. In such cases, the conversation cannot be continued.<br />
          In this event, all personal data that has been stored in the course of the contact will be deleted.</p>

        <p>VIII. Visitor Service Registration Form</p>

        <p>1. Scope and purpose of data processing</p>

        <p>If you contact us using the Visitor Service, you must provide your last name and first name, the institution at which you work, your e-mail address and your telephone number. Without this data, it is not possible for us to process the request you submit via the registration form.<br />
          When you use the registration form, the content of the data fields is transferred to the Federal Press Office Visitor Service. The sender&rsquo;s IP address is recorded at the same time. The date and time of your request are also transferred to us. If we receive a message from you via the registration form or by e-mail, we will assume that we are entitled to reply by e-mail. Otherwise, we kindly ask you to explicitly refer us to another form of communication (such as post, telephone or fax). Your data is processed solely for the purpose of holding an event (digitally via Webex or on site at the Press and Visitor Centre Berlin).</p>

        <p>2. Legal basis</p>

        <p>The content of the registration form is transferred via an encrypted https connection. Processing of the data and content transferred via the registration form (which may also contain personal data provided by you) is based on Article 6 (1) e) in conjunction with Section 3 of the Federal Data Protection Act (BDSG).</p>

        <p>3. Duration of storage</p>

        <p>The request you communicate to us via the registration form is processed by Visitor Service staff. The Visitor Service stores your data in order to process your request and in accordance with legal and contractual requirements. Your data is erased as soon as the purpose for storing it no longer applies.</p>

        <p>4. Rights of the data subject</p>

        <p>You have the right to obtain confirmation from the data controller as to whether your personal data is being processed. You have the right to have the data controller rectify or erase your data or restrict its processing, and you have the right to object to its processing. You have the right to revoke your consent to the processing of personal data at any time. In this event, all personal data stored as a result of your consent will be deleted if there is no other legal basis for its processing.</p>

        <p>IX. Publications portal of the Federal Government</p>

        <p>1. Description and scope of data processing</p>

        <p>On our website you have access to the publications portal of the Federal Government, through which you can order publications. If you make use of this opportunity, the data entered in the input form is sent to the service provider tasked by us to process orders and stored in encrypted format.</p>

        <p>This data is:<br />
          title, first name and surname, company&#8232;street and house number, postal code, city, country&#8232;e-mail.</p>

        <p>At the time of sending the message, the following additional data is stored:</p>

        <p>(1) the IP address of the user<br />
          &#8232;(2) the date and time of registration.</p>

        <p>The service providers tasked with order processing are:<br />
          IBRo Versandservice GmbH&#8232;Kastanienweg 1&#8232;18184 Roggentin<br />
          GVP Gemeinn&uuml;tzige Werkst&auml;tten Bonn GmbH&#8232;Pfaffenweg 27&#8232;53227 Bonn</p>

        <p>During the sending process, your consent is obtained for processing the data for this purpose, and you are referred to this privacy policy statement.<br />
          Alternatively, you can contact the publications dispatch service via the e-mail address provided, by fax, by post or by telephone. In this case, the user&apos;s personal data transmitted together with the e-mail, fax, post or telephone call is stored.<br />
          In this regard, there is no further disclosure of data to other third parties. The data is used solely for the ordering and dispatching process.</p>

        <p>2. Legal basis for data processing</p>

        <p>The legal basis for processing data in the course of sending an e-mail is Article 6 (1) point (b) of the GDPR.</p>

        <p>3. Purpose of data processing</p>

        <p>The processing of personal data from the input form is used solely for performing the ordering and dispatching process. The other personal data processed during the sending process is intended to prevent misuse of the input form, and to ensure the security of our information technology systems.</p>

        <p>4. Duration of storage</p>

        <p>The data is deleted as soon as the purpose for which it was stored no longer applies. The retention period for the personal data entered into the order form, and the data transmitted by e-mail, fax, post or telephone, is three months from the date of the complete dispatch of the order.<br />
          The other personal data collected as part of the sending process is deleted after a maximum period of seven days.</p>

        <p>5. Rights to objection and deletion</p>

        <p>The user has the right to withdraw their consent to the processing of personal data at any time. If the user contacts us by e-mail, among other options, they can object to the storage of their personal data at any time. In such cases, it might not be possible to complete the ordering and dispatching process.</p>

        <p>In this event, all personal data that has been stored in the course of the ordering process will be deleted.</p>

        <p>X. Federal Government app</p>

        <p>The Federal Government app uses push services provided by the manufacturer of the operating system. These are short messages that can be shown on the display of the user&rsquo;s device to alert the user to warnings.<br />
          Should the push services be used, an Apple device token or a Google registration ID is allocated. This is only an encrypted, anonymous device ID. Its only purpose is to allow the delivery of push services. It is not possible to identify the individual user. If necessary, the push service can be modified within the app settings. You can also use the operating system of your smartphone to disable the receipt of push notifications.<br />
          The open source software tool Matomo is used for the app (see Section V).<br />
          In addition, the information provided in Section VI (Newsletter), Section VII (Contact form, e-mail contact and postal contact) and Section VIII (Publications portal of the Federal Government) also apply for the app.</p>

        <p>XI. Information on your rights</p>

        <p>If your personal data is processed, you are a data subject under the provisions of the GDPR and have the following rights that you can demand from your controller:</p>

        <p>1. Right of access by the data subject &ndash; Art. 15 GDPR<br />
          The data subject shall have the right to obtain from the controller confirmation as to whether or not personal data concerning him or her is being processed.</p>

        <p>Where this is the case the data subject is entitled to obtain the following information from the controller:</p>

        <p>(1) the purposes of the processing of personal data;<br />
          (2) the categories of personal data concerned;<br />
          (3) the recipients or categories of recipient to whom the personal data has been or will be disclosed;<br />
          (4) the envisaged period for which the personal data will be stored, or, if no specific information can be given, the criteria used to determine that period;<br />
          (5) the existence of the right to request from the controller rectification or erasure of personal data or restriction of processing of personal data concerning the data subject or to object to such processing;<br />
          (6) the right to lodge a complaint with a supervisory authority;<br />
          (7) where the personal data are not collected from the data subject, any available information as to their source;<br />
          (8) the existence of automated decision-making, including profiling, referred to in Article 22(1) and (4) GDPR and, at least in those cases, meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing for the data subject.<br />
          The data subject is entitled to information on whether pertinent personal data is transferred to a third country or international organisation. Where this is the case, the data subject shall have the right to be informed of the appropriate safeguards pursuant to Article 46 GDPR relating to the transfer. Please also see the Right to data portability, pursuant to Article 20 GDPR.</p>

        <p>2. Right to rectification &ndash; Art. 16 GDPR</p>

        <p>The data subject shall have the right to obtain from the controller the rectification and/or completion of incorrect or incomplete personal data concerning him or her. The controller shall make the corrections without undue delay.<br />
          3. Right to restriction of processing &ndash; Art. 18 GDPR</p>

        <p>The data subject shall have the right to obtain from the controller restriction of processing where one of the following applies:</p>

        <p>(1) the accuracy of the personal data is contested by the data subject, for a period enabling the controller to verify the accuracy of the personal data;<br />
          (2) the processing is unlawful and the data subject opposes the erasure of the personal data and requests the restriction of their use instead;<br />
          (3) the controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defence of legal claims;<br />
          (4) the data subject has objected to processing pursuant to Article 21 (1) GDPR pending the verification whether the legitimate grounds of the controller override those of the data subject.<br />
          Where processing has been restricted, such personal data shall, with the exception of storage, only be processed with the data subject&rsquo;s consent or for the establishment, exercise or defence of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the Union or of a Member State.<br />
          A data subject who has obtained restriction of processing on the basis of the above preconditions shall be informed by the controller before the restriction of processing is lifted.</p>

        <p>4. Right to erasure &ndash; Art. 17 GDPR</p>

        <p>a) Right to be forgotten</p>

        <p>The data subject shall have the right to obtain from the controller the erasure of personal data concerning him or her without undue delay and the controller shall have the obligation to erase personal data without undue delay where one of the following grounds applies:</p>

        <p>(1) the personal data is no longer necessary in relation to the purposes for which it was collected or otherwise processed;<br />
          (2) the data subject withdraws the consent on which the processing is based according to point (a) of Article 6 (1), or point (a) of Article 9 (2) GDPR, and where there is no other legal ground for the processing;<br />
          (3) the data subject objects to the processing pursuant to Article 21 (1) GDPR and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21 (2) GDPR;<br />
          (4) the personal data has been unlawfully processed;<br />
          (5) the personal data has to be erased for compliance with a legal obligation in Union or Member State law to which the controller is subject;<br />
          (6) the personal data has been collected in relation to the offer of information society services referred to in Article 8 (1) GDPR.</p>

        <p>b) Information to third parties</p>

        <p>Where the controller has made the personal data public and is obliged pursuant to Article 17 (1) GDPR to erase the personal data, the controller, taking account of available technology and the cost of implementation, shall take reasonable steps, including technical measures, to inform controllers processing the personal data that the data subject has requested the erasure by such controllers of any links to, or copy or replication of, that personal data.</p>

        <p>c) Exceptions</p>

        <p>The right to be forgotten shall not apply to the extent that processing is necessary:</p>

        <p>(1) for exercising the right of freedom of expression and information;<br />
          (2) for compliance with a legal obligation which requires processing by Union or Member State law to which the controller is subject or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;<br />
          (3) for reasons of public interest in the area of public health in accordance with points (h) and (i) of Article 9 (2) as well as Article 9 (3) GDPR;<br />
          (4) for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes in accordance with Article 89 (1) GDPR in so far as the right referred to in paragraph a) is likely to render impossible or seriously impair the achievement of the objectives of that processing; or<br />
          (5) for the establishment, exercise or defence of legal claims.</p>

        <p>5. Notification obligation &ndash; Art. 19 GDPR</p>

        <p>Should the data subject have made use of the right to rectification or erasure of data or restriction of processing vis &agrave; vis the controller, the latter is obliged to communicate any rectification or erasure of personal data or restriction of processing to each recipient to whom the personal data has been disclosed, unless this proves impossible or involves disproportionate effort.<br />
          The data subject is entitled to be informed by the controller about those recipients.<br />
          The right to data portability does not apply to processing personal data which is required for performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.</p>

        <p>6. Right to data portability &ndash; Art. 20 GDPR</p>

        <p>The data subject shall have the right to receive the personal data concerning him or her, which he or she has provided to a controller, in a structured, commonly used and machine-readable format and have the right to haven the data transmitted to another controller, where:</p>

        <p>the processing is based on consent pursuant to point (a) of Article 6 (1) or point (a) of Article 9 (2) GDPR or on a contract pursuant to point (b) of Article 6 (1) GDPR; and<br />
          the processing is carried out by automated means.</p>

        <p>In exercising his or her right to data portability pursuant to paragraph 1, the data subject shall have the right to have the personal data transmitted directly from one controller to another, where technically feasible.</p>

        <p>7. Right to object &ndash; Art. 21 GDPR</p>

        <p>The data subject shall have the right to object, on grounds relating to his or her particular situation, at any time to processing of personal data concerning him or her which is based on point (e) of Article 6 (1) GDPR.<br />
          The controller shall no longer process the personal data unless the controller demonstrates compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject or for the establishment, exercise or defence of legal claims.</p>

        <p>8. Right to withdraw consent &ndash; Art. 7 (3) GDPR</p>

        <p>The data subject shall have the right to withdraw his or her consent at any time. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.</p>

        <p>9. Right to lodge a complaint with a supervisory authority &ndash; Art. 77 GDPR</p>

        <p>Without prejudice to any other administrative or judicial remedy, every data subject shall have the right to lodge a complaint with a supervisory authority, if the data subject considers that the processing of personal data relating to him or her infringes the provisions of GDPR.<br />
          The supervisory authority with which the complaint has been lodged shall inform the complainant on the progress and the outcome of the complaint including the possibility of a judicial remedy pursuant to Article 78 GDPR.<br />
          Responsible supervisory authority:</p>

        <p>Der Bundesbeauftragte f&uuml;r den Datenschutz und die Informationsfreiheit&#8232;<br />Graurheindorfer Str. 153&#8232;<br />53117 Bonn<br />
          Telephone: +49 (0)228-997799-0&#8232;<br />Fax: +49 (0)228-997799-5550&#8232;<br />E-Mail:&#160;<a className='underline' href='mailto:poststelle@bfdi.bund.de'>poststelle@bfdi.bund.de</a></p>

        <p>Version: May&#160;2022</p>
      </section>
    </div>
  );
};

