import React from 'react';
import Dots from 'assets/images/mihai-landing-page/black-gradient.png';
import MihaiHero from 'assets/images/mihai-landing-page/mihai-hero.png';
import LinkedinIcon from 'assets/images/mihai-landing-page/linkedin.png';
import { WhiteButton } from '../index';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';

export const HeroSection = () => {
  return (
    <div className='flex relative w-full sm:border-1 border-b-2 border-orange'>
      <div className={classNames('flex flex-col min-h-screen max-h-screen justify-center pt-24 ml-48 text-orange text-6xl font-anton z-10 leading-relaxed tracking-wider',
        'lg:ml-20 lg:pt-16 lg:text-5xl lg:leading-snug',
        'sm:!ml-0 sm:!py-[72px] sm:!text-2xl sm:!leading-normal sm:!min-h-fit sm:mt-16 sm:px-5 sm:items-center sm:text-center')}>
        <p className='text-white'>TOGETHER INTO THE</p>
        <p>FUTURE OF LEADERSHIP.</p>
        <p className={classNames('max-w-2xl	text-white text-2xl font-roboto mb-4 mt-10', 'lg:max-w-lg lg:text-xl lg:mt-8','sm:!text-base')}>Welcome to my personal website! I&apos;m <span className='font-bold'>Mihai Streza</span>, the Founder and CEO of <span className='font-bold'>wondder</span>. </p>
        <p className={classNames('max-w-2xl	text-white text-2xl font-roboto', 'lg:max-w-lg lg:text-xl','sm:!text-base')}>I am a leadership development advocate, and a serial tech entrepreneur devoted to igniting positive change and elevating global consciousness.</p>
        <HashLink className='sm:hidden' to="/contact#book-a-demo">
          <WhiteButton className='self-start mt-16 lg:mt-10'>Book a call</WhiteButton>
        </HashLink>

      </div>
      <img className={`absolute top-0 left-0 w-full h-full object-cover`} src={Dots} alt="dots" />
      <img className={`absolute bottom-0 right-0 w-[46%] z-10 sm:hidden`} src={MihaiHero} alt="mihai hero" />
      <div className='absolute top-0 left-0 right-0 bottom-0 overflow-hidden sm:hidden'>
        <a className='absolute top-40 right-12 z-20 lg:right-20' href='https://www.linkedin.com/in/mihai-streza/' target='_blank' rel="noreferrer"><img className='w-[88px] h-[88px] lg:w-16 lg:h-16' src={LinkedinIcon} /></a>
        <span className='absolute top-8 -right-[47rem] w-[1080px] h-[960px] bg-blue rounded-full' />
      </div>
    </div>
  );
};
