import React, { useEffect, useRef, useState } from 'react';
import { useScrollY, useIsMobile } from "hooks";

export const VideoPlayer = ({ src, className, id = 'vimeo-video', isHome, animatable = true }) => {
  const playerRef = useRef(null);
  const videoPlayerRef = useRef(null);
  const scrollY = useScrollY();
  const [videoVisible, setVideoVisible] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    const options = {
      id: src,
      autopause: 0,
      autoplay: Number(isHome),
      byline: 0,
      title: 0,
      width: '840',
      muted: isHome,
      fullscreen: false,
    };
    const player = new window.Vimeo.Player(id, options);
    playerRef.current = player;

    if (scrollY * 1.2 > videoPlayerRef.current.getBoundingClientRect().y) {
      setVideoVisible(true);
    }
  }, [src, scrollY, id, isHome]);

  return (
    <>
      <div className={` [&>iframe]:absolute [&>iframe]:top-0 [&>iframe]:left-0 [&>iframe]:!w-full [&>iframe]:!h-full pt-[42.25%] ${mobile || !animatable ? 'opacity-100 translate-x-0 duration-300' : videoVisible ? 'opacity-100 translate-x-0 duration-300' : '-translate-x-1/2 opacity-0 duration-100'} ${className || ''}`} id={id}></div>
      <div ref={videoPlayerRef} id="scroll-trigger"></div>
    </>
  );
};
