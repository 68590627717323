import React from 'react';

export const Input = ({ label, type, isActive, className, name = '', labelColor = 'text-blue', checked }) => {
  return (
    <div className={`flex flex-col mb-3 xl:mb-2 ${isActive ? 'duration-300 translate-x-0 opacity-100 hover:delay-0' : 'duration-0 !delay-0 -translate-x-1/4 opacity-0'} ${className}`}>
      {type === 'radio' ? (
        <label className={`inline-flex items-center cursor-pointer ${labelColor}`}>
          <input
            type={type}
            className="opacity-0 absolute pointer-events-none [&:checked+div]:bg-orange"
            name={name}
            checked={checked}
          />
          <div className={`w-10 h-10 border-2 rounded-lg mr-4 flex items-center justify-center}`}>
          </div>
          <span className="font-bold text-xl xl:text-base">{label}</span>
        </label>
      ) : (
        <>
          <label className={`font-bold text-2xl mb-2 xl:text-base xl:mb-1.5 ${labelColor}`}>
            {label}
          </label>
          <input
            type={type}
            className={`text-blue font-bold text-2xl border-2 rounded-lg border-blue p-2 focus:outline-none bg-white xl:text-base xl:p-1.5 sm:!p-3 ${className}`}
            name={name}
          />
        </>
      )}
    </div>
  );
};
