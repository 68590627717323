import React from 'react';
import { HomeSection1, HomeSection2, HomeSection3, HomeSection4, HomeSection5, HomeSection6, HomeSection7, HomeSection8 } from './components';

export const Home = () => {

  return (
    <div className='flex flex-col bg-body-gradient gap-y-1.5 xl:gap-y-1 md:!gap-y-0'>
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      <HomeSection4 />
      <HomeSection5 />
      <HomeSection6 />
      <HomeSection7 />
      <HomeSection8 />
    </div>
  );
};
