import React from 'react';
import { MihaiNavigation, HeroSection, WhiteButton, TransformingLeadership, BlogSection, FutureWithVR, JourneyStartsNow } from './components';
import { VideoPlayer } from 'components';
import classNames from 'classnames';
import { useIsMobile } from "hooks";
import LinkedinIcon from 'assets/images/mihai-landing-page/linkedin.png';

const ABOUT_VIMEO_VIDEO = "https://player.vimeo.com/video/914242970?h=ce2b4d9a96&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

export const MihaiLandingPage = () => {
  const isMobile = useIsMobile();
  return (
    <div className='flex flex-col text-white relative bg-black overflow-hidden'>
      <MihaiNavigation isAbsolute />
      <HeroSection />
      <div className={classNames('flex flex-col max-w-[1100px] w-full px-16 py-48 self-center',
        'lg:py-24 lg:px-20',
        'sm:!px-10 sm:!py-20'
      )}>
        <VideoPlayer className='w-full pt-[56.3%]' src={ABOUT_VIMEO_VIDEO} animatable={false} />
        <div className={classNames('flex flex-col items-end mt-36 text-orange text-6xl font-anton z-10 leading-relaxed tracking-wider',
          'lg:text-5xl lg:leading-snug lg:mt-20',
          'sm:!text-2xl sm:!leading-normal sm:items-center sm:text-center')}>
          <p className='text-white'>WHAT WILL YOU DO WITH</p>
          <p className='sm:border-b-2 sm:border-color-orange sm:w-full sm:pb-6'><span className='text-white relative before:content-[""] before:absolute before:top-1/2 before:right-[150%] before:-translate-y-1 before:bg-orange before:w-[70vw] before:h-2 sm:before:hidden'>THE</span> LIMITED TIME?</p>
          <p className='text-white text-2xl font-roboto my-16 text-right lg:my-12 lg:text-xl sm:!text-base sm:!text-center sm:!mt-10 sm:!mb-0'>I emphasize the profound question that often goes unexplored in our busy lives: What are we all doing here? We invest a significant portion of our limited time—approximately 80,000 hours—in our careers. The exploration of purpose and the intentional use of this finite resource become critical. I delve into the importance of this question and its relevance to personal and leadership development.</p>
          <WhiteButton className='self-end sm:hidden' href="#">READ WONDDER BLOG</WhiteButton>
        </div>
      </div>
      <TransformingLeadership />
      <BlogSection />
      {!isMobile ? 
        <><FutureWithVR /><JourneyStartsNow /></> :
        <div className='flex justify-center items-center w-full bg-black'>
          <a className='pt-16 pb-10' href='https://www.linkedin.com/company/wondder/' target='_blank' rel="noreferrer">
            <img className='w-[88px] h-[88px]' src={LinkedinIcon} />
          </a>
        </div>
      }
      
      <MihaiNavigation />
    </div>
  );
};
