import React, { useState, useEffect, useRef } from 'react';
import SectionBg from 'assets/images/mary.jpg';
import cn from 'classnames';
import { isBlockVisibleOnScreen } from "helpers/utils";

export const HomeSection3 = () => {
  const [active, setActive] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActive, [0.2, 0.8]);
  }, []);

  return (
    <div ref={sectionRef} className='flex relative pt-[57%] overflow-hidden lg:pt-[73%] md:!pt-[145%] md:flex-col md:pr-10 md:items-end'>
      <img className='absolute top-0 left-0 w-full md:object-cover md:h-[250%] md:!object-[60.4%]' src={SectionBg} alt="mary vr assistant" loading="lazy" />
      <span className='hidden md:block absolute bottom-0 right-0 w-full h-3/4  bg-blue-gradient-to-top pointer-events-none'></span>

      <div className='absolute top-[16%] left-[62%] max-w-[22rem] lg:top-12 lg:max-w-80 md:relative md:!left-[unset] md:!top-[unset] md:!max-w-60'>
        <p className={`text-4xl text-white font-bold mb-7 transition-all duration-500 xl:text-2xl ${active ? "opacity-100 translate-x-0 delay-700" : "opacity-0 -translate-x-1/4 delay-0"} md:!text-7xl`}>See</p>
        <p className={`text-base text-white font-medium leading-tight duration-500 xl:text-sm ${active ? "opacity-100 translate-x-0 delay-1000" : "opacity-0 -translate-x-1/4 delay-0"} md:text-justify	`}>Experiencing the world from another&apos;s viewpoint in VR cultivates empathy and understanding by immersing us in their perspective, offering a firsthand encounter with diverse outlooks.</p>
        <span
          className={cn("w-4 h-4 rounded-full bg-orange absolute top-[6.25rem] -left-[27%]  duration-300 transition-all xl:w-3 xl:h-3 xl:top-9 xl:-left-6 ",
            "before:content-'' before:absolute before:top-[20%] before:left-[80%] before:h-0.5",
            "before:bg-orange before:-rotate-[32deg] before:origin-top-left before:duration-300 before:transition-all",
            "after:content-'' after:absolute after:-top-12 after:left-[calc(90%+5rem)] after:h-0.5",
            "after:bg-orange after:duration-300 after:transition-all after:rounded-lg",
            (active ? "scale-100 before:w-24 before:delay-300 after:w-60 after:delay-500" : "scale-0 before:w-0 after:w-0 before:delay-0 after:delay-0"),
            "xl:before:hidden xl:after:top-1 xl:after:left-3 md:hidden",
          )}
        />
      </div>
      <div className='absolute top-[44%] right-[62%] max-w-[22rem] text-right lg:top-1/3 lg:right-[65%] lg:max-w-72 md:relative md:!right-[unset] md:!top-[unset] md:text-left md:my-10 md:!max-w-60'>
        <p className={`text-4xl text-white font-bold mb-7 transition-all duration-500 xl:text-2xl ${active ? "opacity-100 translate-x-0 delay-700" : "opacity-0 translate-x-1/4 delay-0"} md:!text-7xl`}>Speak</p>
        <p className={`text-base text-white font-medium leading-tight duration-500 xl:text-sm ${active ? "opacity-100 translate-x-0 delay-1000" : "opacity-0 translate-x-1/4 delay-0"}  md:text-justify	`}>Our AI-driven characters simulate realistic dialogue, responding dynamically to users&apos; queries and actions, creating an immersive experience that feels remarkably human.</p>
        <span
          className={cn("w-4 h-4 rounded-full bg-orange absolute -top-2 -right-[27%] duration-300 transition-all xl:w-3 xl:h-3 xl:top-9 xl:-right-6",
            "before:content-'' before:absolute before:bottom-[25%] before:right-[80%] before:h-0.5",
            "before:bg-orange before:-rotate-[32deg] before:origin-bottom-right before:duration-300 before:transition-all",
            "after:content-'' after:absolute after:top-[61px] after:right-[calc(90%+5rem)] after:h-0.5",
            "after:bg-orange after:duration-300 after:transition-all after:rounded-lg",
            (active ? "scale-100 before:w-24 before:delay-300 after:w-60 after:delay-500" : "scale-0 before:w-0 after:w-0 before:delay-0 after:delay-0"),
            "xl:before:hidden xl:after:top-1.5 xl:after:right-3 md:hidden",
          )}
        />
      </div>
      <div className='absolute top-[63.5%] left-[72%] max-w-[22rem] xl:left-[65%] lg:top-[68%] lg:!left-[60%] lg:max-w-80 md:static md:mb-16 md:!max-w-60'>
        <p className={`text-4xl text-white font-bold mb-7 transition-all duration-500 xl:text-2xl ${active ? "opacity-100 translate-x-0 delay-700" : "opacity-0 -translate-x-1/4 delay-0"} md:!text-7xl`}>Learn</p>
        <p className={`text-base text-white font-medium leading-tight duration-500 xl:text-sm ${active ? "opacity-100 translate-x-0 delay-1000" : "opacity-0 -translate-x-1/4 delay-0"} md:text-justify	`}>VR training&apos;s immersive and interactive nature accelerates learning by creating memorable, engaging, and adaptable experiences that promote active participation and skill application.</p>
        <span
          className={cn("w-4 h-4 rounded-full bg-orange absolute -top-2 -left-[27%] duration-300 transition-all xl:w-3 xl:h-3 xl:top-9 xl:-left-6",
            "before:content-'' before:absolute before:bottom-[25%] before:left-[80%] before:h-0.5",
            "before:bg-orange before:rotate-[32deg] before:origin-top-left before:duration-300 before:transition-all",
            "after:content-'' after:absolute after:top-[61px] after:left-[calc(85%+5rem)] after:h-0.5",
            "after:bg-orange after:duration-300 after:transition-all",
            (active ? "scale-100 before:w-24 before:delay-300 after:w-60 after:delay-500" : "scale-0 before:w-0 after:w-0 before:delay-0 after:delay-0"),
            "xl:before:hidden xl:after:top-1 xl:after:left-3 md:hidden",
          )}
        />
      </div>
    </div>
  );
};
