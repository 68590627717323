import React from 'react';
import { Link } from 'react-router-dom';

export const WhiteButton = ({ children, href, className = '' }) => {
  return href ? (
    <Link to={href} className={`font-roboto font-bold uppercase text-2xl !leading-none py-3.5 px-6 bg-white rounded-xl text-black duration-200	border-2 border-white min-lg:hover:bg-orange min-lg:hover:text-white ${className} min-lg:!hover:delay-0 xl:text-base md:py-3 md:px-5 md:!text-xl md:!rounded-2xl md:!leading-none`}>{children}</Link>
  ) : (
    <button className={`font-roboto font-bold uppercase text-2xl py-3.5 px-6 !leading-none bg-white rounded-xl text-black duration-200	border-2 border-white min-lg:hover:bg-orange min-lg:hover:text-white ${className} min-lg:!hover:delay-0 xl:text-base md:!py-3 md:!px-5 md:!text-xl md:!rounded-2xl md:!leading-none`}>{children}</button>
  );
};

