import React from 'react';
import Bg from 'assets/images/sscalendly.png';
import cn from 'classnames';

export const SuccessStoriesSection5 = () => {
  return (
    <section className="flex items-center justify-center relative overflow-hidden pt-48 pb-56 lg:pt-24 lg:pb-28 lg:flex-col-reverse sm:!pt-32">
      <div className='h-[39em] w-[26rem] lg:w-[690px] lg:h-[690px] sm:!w-[345px] sm:!h-[620px] z-20 overflow-hidden bg-white'>
        <iframe width='100%' height='100%' src="https://meetings-eu1.hubspot.com/meetings/alessio-dell-anna?embed=true"></iframe>
      </div>
      <p className='text-5xl text-white font-bold ml-20 leading-tight	z-20 lg:mb-10 lg:text-3xl lg:ml-0 sm:!text-2xl sm:text-blue'>BOOK A MEETING<br /> WITH US!</p>
      <div className={cn('absolute -bottom-[36%] w-full top-[38%] lg:top-[48%] lg:bottom-0 ',
        'before:content-[""] before:absolute before:top-0 before:left-0 before:right-0 before:z-10 before:bg-black before:opacity-70 before:h-full sm:before:!bg-blue sm:before:opacity-80')}>
        <img src={Bg} className='absolute top-0 left-0 w-full sm:w-[unset] sm:object-cover sm:h-full' alt="vr background" loading="lazy" />
      </div>
    </section>
  );
};
