import React from 'react';
import { VideoPlayer, OrangeButton } from 'components';
import SectionBg from 'assets/images/Junus.png';

const ROUNDTABLE_VIMEO_VIDEO = "https://player.vimeo.com/video/914242970?h=ce2b4d9a96&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
const FGB_VIMEO_VIDEO = "https://player.vimeo.com/video/915216156?h=c5376c5c42&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
const WWD_VIMEO_VIDEO = "https://player.vimeo.com/video/914766764?h=9641bae220&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
const TTM_VIMEO_VIDEO = "https://player.vimeo.com/video/831644945?h=11da46f609&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
const GFB_VIMEO_VIDEO = "https://player.vimeo.com/video/831647913?h=c0050f163c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

export const RoundTable = ({ product }) => {
  const isLD = product === 'LD';

  return (
    <div className='flex flex-col bg-dark-blue p-40 lg:p-20 sm:!p-0 sm:!py-10 overflow-hidden relative pb-[32%] lg:pb-[32%]'>
      <section className='flex flex-col z-20'>
        <p className='text-3xl text-white font-bold text-center lg:text-xl sm:!text-base sm:px-10'>THANKS FOR BEING PART OF OUR ONLINE ROUNDTABLE!</p>
        <p className='text-3xl text-white font-normal text-center mt-10 lg:text-xl lg:mt-6 sm:!text-base sm:px-10'>{isLD ? "We're offering an exclusive opportunity: you can test our Virtual Reality Leadership Development Trainings yourself!" : "We’re offering you an exclusive discount: rent the headset for a week at a reduced price."}</p>
        <div className='w-full my-32 px-20 lg:my-20 lg:px-0 sm:!mt-16 md:!my-8'>
          <VideoPlayer className='w-full h-auto pt-[56.3%] md:w-full md:pt-[56.3%] !translate-x-0 !opacity-100' src={ROUNDTABLE_VIMEO_VIDEO} />
        </div>
        <p className='text-3xl text-white font-bold text-center lg:text-xl sm:!text-base sm:px-10'>Get an exclusive sneak peek into our innovative VR training programs.</p>
        <div className='w-3/4 self-center mt-32 px-20 lg:my-20 lg:px-0 sm:!mt-16 mb-8 md:!my-8'>
          <VideoPlayer className='w-full h-auto pt-[56.3%] md:w-full md:pt-[56.3%] !translate-x-0 !opacity-100' src={FGB_VIMEO_VIDEO} id="fgb-video" />
        </div>
        <p className='text-3xl text-white font-bold text-center lg:text-xl sm:!text-base sm:px-10'>Face Gender Bias:<br /> A Day in the Office</p>
        <div className='w-3/4 self-center mt-32 px-20 lg:my-20 lg:px-0 sm:!mt-16 mb-8 md:!my-8'>
          <VideoPlayer className='w-full h-auto pt-[56.3%] md:w-full md:pt-[56.3%] !translate-x-0 !opacity-100' src={isLD ? TTM_VIMEO_VIDEO : WWD_VIMEO_VIDEO} id={isLD ? "ttm-video" : "wwd-video"} />
        </div>
        <p className='text-3xl text-white font-bold text-center lg:text-xl sm:!text-base sm:px-10'>{isLD ? `Talk To Me` : 'Working With Disabilities'}</p>
        {isLD &&
          <>
            <div className='w-3/4 self-center mt-32 px-20 lg:my-20 lg:px-0 sm:!mt-16 mb-8 md:!my-8'>
              <VideoPlayer className='w-full h-auto pt-[56.3%] md:w-full md:pt-[56.3%] !translate-x-0 !opacity-100' src={GFB_VIMEO_VIDEO} id="gfb-video" />
            </div>
            <p className='text-3xl text-white font-bold text-center lg:text-xl sm:!text-base sm:px-10'>Giving Feedback </p>
          </>
        }
        <OrangeButton href="/our-solutions" className='self-center my-32 lg:my-20 sm:!my-10 sm:!mx-5 whitespace-nowrap'>DISCOVER ALL VR PRODUCTS</OrangeButton>
        <a className='text-6xl lg:text-4xl lg:px-16 lg:py-12 text-white font-bold !leading-none bg-orange border-4 border-orange rounded-[40px] px-24 py-20 text-center self-center hover:min-lg:bg-white hover:min-lg:text-orange duration-300 cursor-pointer md:!px-12 md:!py-6 md:!text-xl md:rounded-[20px] sm:w-[calc(100%-40px)]' href='https://buy.stripe.com/28o9E82Libv82Ck6oq' target="_blank" rel="noreferrer">ORDER FOR <span className='text-blue'>499 EUR</span>*!</a>
        <p className='text-2xl text-white font-normal self-center text-center mt-2 lg:text-base sm:!text-sm sm:px-10 sm:text-blue'>*Includes standard shipping costs within EU, excludes VAT</p>
      </section>
      <span className='absolute bg-[#D9D9D9] bottom-[-100vw] -left-[15vw] w-[130vw] h-[130vw] rounded-full pointer-events-none sm:bottom-[-28%]'></span>
      <img className='absolute w-[58%]  bottom-[-5%] right-[-16%] sm:-bottom-20 sm:top-[unset] sm:w-[160%] sm:-right-1/4 sm:max-w-[unset] sm:h-[inherit] sm:hidden' src={SectionBg} alt="vr background" />
    </div>
  );
};
