import React from 'react';
import { WhiteButton } from '../index';
import TeamPicBottom from 'assets/images/team-picture.png';
import LinkedinIcon from 'assets/images/mihai-landing-page/linkedin.png';
import classNames from 'classnames';

export const JourneyStartsNow = () => {
  return (
    <div className='flex justify-center relative w-full mt-40 overflow-hidden lg:mt-20'>
      <div className='relative w-screen pt-[59.5%] overflow-hidden grayscale lg:pt-[66.5%]'>
        <img className='w-full h-full absolute top-0 left-0 object-cover brightness-75' src={TeamPicBottom} />
      </div>
      <div className='flex flex-col absolute bottom-1/2 left-40 translate-y-[60%] justify-start lg:left-20 lg:translate-y-1/2'>
        <div className={classNames('flex flex-col justify-start text-white text-6xl font-anton z-30 leading-relaxed	tracking-wider',
          'lg:text-5xl lg:leading-snug ')}>
          <p>THE JOURNEY</p>
          <p className='text-orange'>STARTS NOW</p>
          <p className='max-w-[38rem]	text-white text-2xl font-roboto mb-4 mt-10 lg:mt-7 lg:text-xl  lg:max-w-lg'>I share wondder&apos;s journey as a bridge between technology and personal development. I delve into the significance of bringing transformational tools to enterprises, starting with leadership development and expanding to impact millions of employees worldwide.
          </p>
        </div>
        <WhiteButton className='self-start mt-10 lg:mt-7' href="https://wondder.io/">Visit wondder</WhiteButton>
      </div>
      <div className={classNames('absolute w-[33vw] h-[33vw] -bottom-[16vw] -right-[15vw] bg-blue rounded-full',
        'before:absolute before:right-1/3 before:bottom-full before:translate-y-1/2 before:w-[8vw] before:h-[8vw] before:border before:border-white before:rounded-full')} >
        <a className='absolute top-1/4 left-[22%] z-20' href='https://www.linkedin.com/company/wondder/' target='_blank' rel="noreferrer"><img className='w-[88px] h-[88px] lg:w-16 lg:h-16' src={LinkedinIcon} /></a>
      </div>
    </div>
  );
};
