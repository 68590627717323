import React, { useEffect, useState, useRef } from 'react';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import SPP from 'assets/images/spp-external-use.jpg';
import CL from 'assets/images/CL Mayors Meeting 01 EXTERNAL USE.jpg';
import GF from 'assets/images/GF Conversation from above EXTERNAL USE.jpg';
import { useIsMobile } from "hooks";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

export const SolutionsSection3 = () => {
  const section1Ref = useRef();
  const section2Ref = useRef();
  const section3Ref = useRef();
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    isBlockVisibleOnScreen(section1Ref.current, setActive1, [0.4, 1]);
    isBlockVisibleOnScreen(section2Ref.current, setActive2, [0.4, 1]);
    isBlockVisibleOnScreen(section3Ref.current, setActive3, [0.4, 1]);
  }, []);

  return (
    <section className='flex flex-col items-center relative md:mt-32 md:px-10 md:gap-y-16'>
      {mobile ?
        <div className='relative w-full'>
          <Swiper
            navigation={{
              prevEl: '.prev',
              nextEl: '.next',
            }}
            slidesPerView={1}
            spaceBetween={80}
            modules={[Navigation]}
            className="w-full"
          >

            <SwiperSlide className='z-20 duration 150 group '>
              <div className='flex flex-col items-center scale-75 duration-150 delay-100 origin-top group-[.swiper-slide-active]:scale-100'>
                <img className={cn('w-[calc(100vw-100px)] h-[calc(100vw-100px)] object-cover rounded-full border-4 border-white p-3')} src={SPP} />
                <div className='flex flex-col items-center mt-10'>
                  <p className={`text-white text-3xl mb-10 font-bold text-center`}>Sales Pitch Pro</p>
                  <p className={`text-white text-base text-justify font-normal`}>Sales Pitch Pro revolutionizes professional development with immersive VR training, offering participants a cutting-edge platform to master sales skills through realistic simulations, empowering them to deliver compelling pitches with confidence and precision.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='z-20 duration 150 group '>
              <div className='flex flex-col items-center  scale-75 duration-150 delay-100 origin-top group-[.swiper-slide-active]:scale-100'>
                <img className={cn('w-[calc(100vw-100px)] h-[calc(100vw-100px)] object-cover object-left rounded-full border-4 border-white p-3')} src={CL} />
                <div className='flex flex-col items-center mt-10'>
                  <p className={`text-white text-3xl mb-10 font-bold text-center`}>Ignite Change</p>
                  <p className={`text-white text-base text-justify font-normal`}>Elevate your skills with our VR training—master tough decisions, inspire change, and refine negotiation and persuasion techniques for impactful professional growth.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='z-20 duration 150 group '>
              <div className='flex flex-col items-center scale-75 duration-150 delay-100 origin-top group-[.swiper-slide-active]:scale-100'>
                <img className={cn('w-[calc(100vw-100px)] h-[calc(100vw-100px)] object-cover rounded-full border-4 border-white p-3 z-20')} src={GF} />
                <div className='flex flex-col items-center mt-10'>
                  <p className={`text-white text-3xl mb-10 font-bold text-center `}>Giving Feedback</p>
                  <p className={`text-white text-base text-justify font-normal`}>Unlock leadership excellence with our VR training, guiding participants to deliver constructive feedback and inspire team motivation, fostering a culture of growth and leadership mastery.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <span style={{ clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }} className='prev absolute top-[calc(50vw-40px)] -left-1 -translate-y-1/2  -translate-x-full z-50 w-5 h-5 bg-gray -rotate-90 cursor-pointer duration-150 [&.swiper-button-disabled]:opacity-30'></span>
          <span style={{ clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }} className='next absolute top-[calc(50vw-40px)] -right-1 -translate-y-1/2 translate-x-full  z-50 w-5 h-5 bg-gray rotate-90 cursor-pointer duration-150 [&.swiper-button-disabled]:opacity-30'></span>
        </div>
        :
        <>
          <div ref={section1Ref} className='relative flex items-center z-20'>
            <img className={cn('w-[28vw] h-[28vw] object-cover rounded-full border-4 border-white p-4')} src={SPP} />
            <div className='flex flex-col max-w-[30rem] ml-12'>
              <p className={`text-white text-4xl mb-5 font-bold lg:text-2xl  ${active1 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>Sales Pitch Pro</p>
              <p className={`text-white text-base text-justify font-normal ${active1 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>Sales Pitch Pro revolutionizes professional development with immersive VR training, offering participants a cutting-edge platform to master sales skills through realistic simulations, empowering them to deliver compelling pitches with confidence and precision.</p>
            </div>
          </div>
          <div ref={section2Ref} className='relative flex items-center z-20'>
            <div className='flex flex-col max-w-[30rem] mr-12'>
              <p className={`text-white text-4xl mb-5 font-bold lg:text-2xl ${active2 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>Ignite Change</p>
              <p className={`text-white text-base text-justify font-normal ${active2 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>Elevate your skills with our VR training—master tough decisions, inspire change, and refine negotiation and persuasion techniques for impactful professional growth.</p>
            </div>
            <img className={cn('w-[28vw] h-[28vw] object-cover object-left rounded-full border-4 border-white p-4')} src={CL} />
          </div>
          <div ref={section3Ref} className='relative flex items-center z-20'>
            <img className={cn('w-[28vw] h-[28vw] object-cover rounded-full border-4 border-white p-4 z-20')} src={GF} />
            <div className='flex flex-col max-w-[30rem] ml-12'>
              <p className={`text-white text-4xl mb-5 font-bold lg:text-2xl  ${active3 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>Giving Feedback</p>
              <p className={`text-white text-base text-justify font-normal ${active3 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>Unlock leadership excellence with our VR training, guiding participants to deliver constructive feedback and inspire team motivation, fostering a culture of growth and leadership mastery.</p>
            </div>
          </div>
        </>
      }
      <div className='absolute right-0 bg-orange rounded-s-full w-[45vw] h-[90vw] top-[33%] sm:hidden' />
    </section>
  );
};
