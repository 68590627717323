import React from 'react';
import { Link } from 'react-router-dom';

export const OrangeButton = ({ children, href, className = '', ...props }) => {
  return href ? (
    <Link {...props} to={href} className={`text-2xl !leading-none p-2.5 bg-orange rounded-xl text-white duration-200	border-2 border-orange min-lg:hover:bg-white min-lg:hover:text-orange ${className} min-lg:!hover:delay-0 xl:text-base md:py-3 md:px-5 md:!text-xl md:!rounded-2xl md:!leading-none`}>{children}</Link>
  ) : (
    <button {...props} className={`text-2xl p-2.5 !leading-none bg-orange rounded-xl text-white duration-200	border-2 border-orange min-lg:hover:bg-white min-lg:hover:text-orange ${className} min-lg:!hover:delay-0 xl:text-base md:!py-3 md:!px-5 md:!text-xl md:!rounded-2xl md:!leading-none`}>{children}</button>
  );
};

