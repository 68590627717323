import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import React, { useEffect, useState, useRef } from 'react';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import Arb from 'assets/images/Airbus-Logo.png';
import Acc from 'assets/images/Accenture logo.png';
import Add from 'assets/images/runtastic logo.png';
import Hcm from 'assets/images/Holcim_logo.png';
import Pum from 'assets/images/puma-logo.png';
import { Autoplay } from 'swiper/modules';
import { useIsMobile } from "hooks";

export const SuccessStoriesSection4 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActive, [0.4, 1]);
  }, []);

  return (
    <section ref={sectionRef} className='sm:relative '>
      {mobile && <span className='bg-gray absolute -top-[150%] left-0 -translate-x-[calc(50%-50vw)] rounded-full w-[100rem] h-[100rem] ' />}
      <p className={cn("text-white font-bold text-7xl text-center max-w-[80%] ml-[auto] mr-[auto] mb-40 -translate-x-1/2 opacity-0 duration-300 lg:text-4xl lg:mb-20 sm:!text-2xl sm:text-blue sm:max-w-full", { "translate-x-0 opacity-100": active })}>JOIN THE CIRCLE OF PROGRESSIVE THINKERS!</p>
      <div className='w-full bg-white min-md:py-4'>
        <Swiper
          className='cursor-pointer max-w-[calc(100vw-80px)]'
          spaceBetween={24}
          slidesPerView={mobile ? 3 : 6}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Arb} alt='slider img' /></SwiperSlide>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Acc} alt='slider img' /></SwiperSlide>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Add} alt='slider img' /></SwiperSlide>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Hcm} alt='slider img' /></SwiperSlide>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Pum} alt='slider img' /></SwiperSlide>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Arb} alt='slider img' /></SwiperSlide>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Acc} alt='slider img' /></SwiperSlide>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Add} alt='slider img' /></SwiperSlide>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Hcm} alt='slider img' /></SwiperSlide>
          <SwiperSlide className='relative w-[16.6%] rounded-lg pt-[6%] !flex items-center justify-center lg:rounded-md sm:pt-[14%] sm:bg-transparent'><img className="top-0 left-0 absolute w-full h-full object-contain" src={Pum} alt='slider img' /></SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};
