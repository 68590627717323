import React from 'react';

export const Textarea = ({ label, isActive, className, name = '', labelColor = 'text-blue' }) => {
  return (
    <div className={`flex flex-col mb-3 xl:mb-2 ${isActive ? 'duration-300 delay-700 translate-x-0 opacity-100 hover:delay-0' : 'duration-0 !delay-0  -translate-x-1/4 opacity-0'} ${className}`}>
      <label className={`font-bold text-2xl mb-2 xl:text-base xl:mb-1.5 ${labelColor}`}>{label}</label>
      <textarea name={name} className='text-blue font-bold text-2xl border-2 rounded-lg border-blue p-2 focus:outline-none resize-none	bg-white xl:text-lg xl:p-1.5 sm:!p-3' />
    </div>
  );
};
