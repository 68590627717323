import React, { useEffect, useState, useRef } from 'react';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';

export const SuccessStoriesSection3 = () => {
  const block1Ref = useRef();
  const block2Ref = useRef();
  const block3Ref = useRef();
  const [block1Active, setBlock1Active] = useState(false);
  const [block2Active, setBlock2Active] = useState(false);
  const [block3Active, setBlock3Active] = useState(false);

  useEffect(() => {
    isBlockVisibleOnScreen(block1Ref.current, setBlock1Active, [0.7, 1]);
    isBlockVisibleOnScreen(block2Ref.current, setBlock2Active, [0.7, 1]);
    isBlockVisibleOnScreen(block3Ref.current, setBlock3Active, [0.7, 1]);
  }, []);

  return (
    <section className='flex flex-col pt-44 pb-72 pr-44 overflow-hidden lg:pr-10 lg:pt-20 lg:pb-40 sm:px-10 sm:!py-16 sm:z-50'>
      <div ref={block1Ref} className='max-w-xl self-end flex flex-col sm:text-right'>
        <p className={cn('text-2xl italic text-white text-light translate-x-1/2 opacity-0 duration-300 lg:text-lg sm:!text-base', { "!translate-x-0 opacity-100": block1Active })}>&apos;&apos;To walk a mile in somebody&apos;s shoes is a very effective way to create awareness for another person&apos;s situation.&apos;&apos;</p>
        <div className='flex flex-col items-end self-start font-bold text-2xl text-white mt-16 lg:text-lg lg:mt-8 sm:self-end sm:items-end sm:text-right sm:!mt-16'>
          <p className={cn('mb-2 -translate-x-1/2 opacity-0 duration-300 self-start sm:w-full sm:!text-base', { "!translate-x-0 opacity-100": block1Active })}>Stefan Pfister</p>
          <p className={cn('translate-x-1/2 opacity-0 duration-300 sm:!text-base', { "!translate-x-0 opacity-100": block1Active })}>CEO KPMG Switzerland</p>
        </div>
      </div>
      <div className='relative w-[90vw] h-[90vw] mt-28 mb-10 flex flex-col justify-center pr-32 lg:mt-10 lg:mb-4 sm:pr-0 sm:w-full sm:h-full sm:!mt-32'>
        <span className='absolute top-0 left-0 w-[80%] h-[80%] -translate-x-[40%] sm:hidden'>
          <span className='bg-gray absolute top-0 left-0 w-full h-full	rounded-full' />
        </span>
        <div ref={block2Ref} className='max-w-xl flex flex-col mix-blend-difference z-10 ml-20 lg:max-w-md lg:ml-10 sm:!mix-blend-normal sm:!ml-0 -translate-y-1/2'>
          <p className={cn('	text-2xl italic text-creme text-light  -translate-x-1/2 opacity-0 duration-300 lg:text-lg sm:text-white sm:not-italic	sm:!text-base', { "!translate-x-0 opacity-100": block2Active })}>&apos;&apos;Experiencing a woman&apos;s day through VR brought profound understanding.&apos;&apos;</p>
          <div className='flex flex-col items-start self-start font-bold text-2xl text-blue mt-16 lg:text-lg lg:mt-8 sm:!mt-16'>
            <p className={cn('mb-2 translate-x-1/2 opacity-0 duration-300 text-creme sm:text-white sm:!text-base', { "!translate-x-0 opacity-100": block2Active })}>Nadja Kröll </p>
            <p className={cn('-translate-x-1/2 opacity-0 duration-300 text-creme sm:text-white sm:!text-base', { "!translate-x-0 opacity-100": block2Active })}>Managing Director, Accenture</p>
          </div>
        </div>
      </div>
      <div ref={block3Ref} className='max-w-xl self-end flex flex-col items-end sm:mt-40'>
        <p className={cn('text-2xl italic text-white text-light translate-x-1/2 opacity-0 duration-300 lg:text-lg sm:text-blue sm:!text-base sm:not-italic', { "!translate-x-0 opacity-100": block3Active })}>&apos;&apos;VR is some powerful stuff.&apos;&apos;</p>
        <div className='flex flex-col items-end self-start font-bold text-2xl text-white mt-16 lg:text-lg lg:mt-8 sm:!mt-16 sm:text-blue'>
          <p className={cn('mb-2 -translate-x-1/2 opacity-0  duration-300 sm:!text-base', { "!translate-x-0 opacity-100": block3Active })}>Abel Buko</p>
          <p className={cn('translate-x-1/2 opacity-0 duration-300 sm:!text-base', { "!translate-x-0 opacity-100": block3Active })}>Diversity and CSR Manager, Zimpler</p>
        </div>
      </div>
    </section>
  );
};

