import React, { useEffect, useState, useRef } from 'react';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import CircleArc from 'assets/images/circle-arc.png';
import { useIsMobile } from "hooks";

export const SolutionsSection6 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    if (mobile) {
      setActive(true);
    } else {
      isBlockVisibleOnScreen(sectionRef.current, setActive, [0.3, 1]);
    }
  }, [mobile]);
  return (
    <section id="well-being-experience" ref={sectionRef} className='flex relative mt-[12rem] z-50 sm:!mt-32'>
      <div className='flex relative justify-between items-center lg:w-full pr-12 sm:flex-col-reverse sm:px-10'>
        <div className='flex flex-col max-w-[32rem] ml-[10rem] min-xl:ml-[30rem] lg:ml-12 lg:max-w-[20rem] sm:mt-16 sm:text-center sm:!ml-0'>
          <p className={`text-6xl font-bold text-white xl:text-4xl ${active ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>EMPLOYEE WELLBEING</p>
          <p className={`text-base font-medium text-white text-left mt-10 xl:text-lg  xl:mt-8 lg:!text-base md:!mt-6 sm:text-center  ${active ? 'duration-300 delay-100 translate-x-0  opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>At wondder we develop immersive VR learning methods based on proven scientific research to develop professional skills, reduce bias, grow mental health, and achieve a more inclusive culture.</p>
        </div>
        <div className='relative w-[30vw] h-[30vw] flex flex-col items-center justify-center sm:w-[calc(100vw-80px)] sm:h-[calc(100vw-80px)]'>
          <img className={cn('absolute top-0 left-0 w-full duration-200 -rotate-180',
            { 'rotate-0 delay-200': active }
          )} src={CircleArc} alt='circle arc' />
          <p className='text-white text-3xl z-20 text-center lg:text-lg'>400% higher <br /> participant focus</p>
        </div>
      </div>
    </section>
  );
};
