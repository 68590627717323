import React, { useState, useContext } from "react";
import { clientCredentials } from "helpers/constants";
import { ModalContext } from "context/ModalContext";

export const LoginModal = () => {
  const { isVisibleModal, setIsVisibleModal } = useContext(ModalContext);
  const [error, setError] = useState('');

  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    if (input.username !== "" && input.password !== "") {
      if(input.username === clientCredentials.username && input.password === clientCredentials.password){
        localStorage.setItem('isSignedIn',true);
        setIsVisibleModal(false);
        return;
      } else {
        setError('Invalid username or password.');
        return;
      }
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return isVisibleModal && (
    <div className={`fixed w-screen h-screen overflow-hidden top-0 left-0 right-0 bottom-0 bg-black z-[100] bg-opacity-80`}>
      <div className="absolute top-0 left-0 right-0 bottom-0 z-10" onClick={() => setIsVisibleModal(false)}/>
      <form className="z-20 w-[500px] h-[500px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-10 flex flex-col border-2 border-orange text-blue sm:w-screen sm:h-screen sm:px-4" onSubmit={handleSubmitEvent}>
        <p className="text-2xl self-center">Sign in to download</p>
        <span className="absolute top-2 right-3 cursor-pointer text-4xl font-bold text-blue duration-150 hover:text-orange" onClick={() => setIsVisibleModal(false)}>&times;</span>
        <div className="form_control flex flex-col items-start mt-8">
          <label htmlFor="user-email" className="mb-2 text-lg">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            aria-describedby="user-email"
            aria-invalid="false"
            className="border border-orange rounded-md p-3 text-base w-full text-blue focus-visible:border-blue"
            onChange={handleInput}
          />
          <div id="user-email" className="sr-only">
            Please enter a valid username. It must contain at least 6 characters.
          </div>
        </div>
        <div className="form_control flex flex-col items-start mt-8">
          <label htmlFor="password" className="mb-2 text-lg">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            aria-describedby="user-password"
            aria-invalid="false"
            className="border border-orange rounded-md p-3 text-base text-blue w-full"
            onChange={handleInput}
          />
        </div>
        <button className="mt-10 text-xl p-2.5 !leading-none bg-orange rounded-xl text-white duration-200	border-2 border-orange min-lg:hover:bg-white min-lg:hover:text-orange">Submit</button>
        {error && <p className="text-orange text-sm self-center mt-4">{error}</p>}
      </form>
    </div>
  );
};
