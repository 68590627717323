import React, { useState, useRef } from 'react';
import { OrangeButton, Input } from 'components';
import { VideoPlayer } from 'components';
import emailjs from '@emailjs/browser';
import { emailJsPublicKey, emailJsServiceId, emailJsTemplateIdOnboarding } from 'helpers/constants';
import BoxContentImg from 'assets/images/oculus-quest-2-box-content.png';

const ONBOARD_PAGE_VIDEO = "https://player.vimeo.com/video/924411730?h=aab876a83c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

export const Onboard = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const currentPath = window.location.pathname;

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(emailJsServiceId, emailJsTemplateIdOnboarding, form.current, {
        publicKey: emailJsPublicKey,
      })
      .then(
        () => {
          form.current.reset();
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log('FAILED...', error.text);
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='flex flex-col bg-dark-blue p-40 lg:p-20 sm:!p-0 sm:!py-10 overflow-hidden'>
      <section className='flex flex-col items-center text-center gap-y-10 lg:gap-y-8 sm:!gap-y-6 sm:px-10'>
        <p className='text-white text-3xl lg:text-xl sm:!text-base'>Dear Training Participant!</p>
        <p className='text-white text-3xl lg:text-xl sm:!text-base'>Now its time to meet your Virtual Reality Headset and prepare for the training.</p>
        <p className='text-white text-3xl lg:text-xl sm:!text-base'>You will need ca. {currentPath.includes('axr') ? '20' : '10'} minutes for this preparation.</p>
        <p className='text-orange uppercase text-3xl  font-semibold lg:text-xl sm:!text-base'>PLEASE DO NOT CONNECT THE HEADSET TO THE Wi-Fi</p>
      </section>
      <section className='flex flex-col'>
        <div className='flex flex-col mt-32 gap-y-10 lg:gap-y-8 lg:mt-20 sm:!gap-y-6 sm:px-10 sm:!mt-16'>
          <p className='text-orange text-3xl font-semibold lg:text-xl sm:!text-base'>SAFETY AND HEALTH</p>
          <p className='text-white text-2xl text-justify font-normal lg:text-lg sm:!text-base'>
            The VR headset is only to be used indoors, by people of age 13+ . After a full hour of use,
            it’s recommended to take the headset off for at least 15 minutes.
            We have a strict hygiene concept that complies with Covid-19 regulations. The VR equipment is disinfected by us before being sent out.</p>
          <p className='text-white text-2xl text-justify font-normal lg:text-lg sm:!text-base'>Do you have a fear of heights? Severe motion sickness? These affect an estimated 1% of the global population and can impair VR use. Our VR experiences are tailored in such a way that they do NOT prompt such reactions (there are no sudden movements, etc.). If you feel physical discomfort, you can remove the headset anytime.</p>
        </div>
        <div className='flex flex-col mt-32 gap-y-10 lg:gap-y-8 lg:mt-20 sm:!gap-y-6 sm:px-10 sm:!mt-16'>
          <p className='text-orange text-3xl font-semibold lg:text-xl sm:!text-base'>VR TRAINING - INTRODUCTION</p>
          <p className='text-white text-2xl text-justify font-normal lg:text-lg sm:!text-base'>What you will experience is an interactive experience. You use a VR headset and two controllers to move in the Virtual World. The headset allows you to see and hear the virtual world, the controllers help you interact with it.
            We believe VR training is a private experience. After getting used to the technology, you are usually alone in the room when training.
            We will be available online to assist you if you need us.</p>
          <p className='text-white text-2xl text-justify font-normal lg:text-lg sm:!text-base'>In general, the space in VR is larger than the space you have available in your room. To navigate the much larger VR space you can use the controllers to “teleport”. You will be shown how to do this directly in the VR experience.</p>
        </div>
        <div className='flex flex-col mt-32 gap-y-10 lg:gap-y-8 lg:mt-20 sm:!gap-y-6 sm:!mt-16'>
          <p className='text-orange text-3xl font-semibold lg:text-xl sm:px-10 sm:!text-base'>PACKAGE CONTENT</p>
          <p className='text-white text-2xl font-normal lg:text-lg sm:px-10 sm:!text-base'>When you open the package you should find the following content:</p>
          <ul className='list-disc list-inside ml-3 gap-y-1.5 flex flex-col sm:px-8'>
            <li className='text-white text-2xl font-normal lg:text-lg sm:!text-base'>The headset</li>
            <li className='text-white text-2xl font-normal lg:text-lg sm:!text-base'>Two controllers</li>
            <li className='text-white text-2xl font-normal lg:text-lg sm:!text-base'>A charger</li>
            <li className='text-white text-2xl font-normal lg:text-lg sm:!text-base'>A charging cable (USB-C to USB-C)</li>
          </ul>
          <div className='w-full my-32 px-20 lg:my-20 lg:px-0 sm:!my-16'>
            <img className='w-full h-auto' src={BoxContentImg} alt='box content' />
          </div>
        </div>
      </section>
      <section className='flex flex-col'>
        <p className='text-3xl text-white text-center lg:text-xl sm:!text-base sm:px-10'>Here is an onboarding video with all the information you need as preparation</p>
        <div className='w-full mt-32 px-20 lg:mt-20 lg:px-0 sm:!mt-16'>
          <VideoPlayer className='w-full h-auto pt-[56.3%] md:w-full md:pt-[56.3%] md:translate-x-0 md:opacity-100' src={ONBOARD_PAGE_VIDEO} />
        </div>
      </section>
      {(currentPath.includes('onboard_advance_en') || currentPath.includes('onboard_axr_en')) && (
        <form ref={form} onSubmit={sendEmail} className='email-form self-center mt-56 flex flex-col gap-y-14 w-full max-w-[40rem] lg:px-20 md:mt-20 sm:min-w-full lg:!gap-y-8 sm:!px-10 sm:items-center'>
          <Input name="user_name" className='sm:items-center' labelColor="text-white sm:!mb-6" label='Your Name' type='text' isActive />
          <p className='font-bold text-xl ml-2 xl:text-base xl:ml-1.5 text-white sm:text-center'>I have received my VR Headset</p>
          <div className='flex gap-x-10'>
            <Input name="vr_recieved" labelColor="text-white" label='Yes' type='radio' isActive />
            <Input name="vr_recieved" labelColor="text-white" label='No' type='radio' isActive />
          </div>
          <p className='font-bold text-xl ml-2 xl:text-base xl:ml-1.5 text-white sm:text-center'>I have watched the VR preparation video and done all the described steps</p>
          <div className='flex gap-x-10'>
            <Input name="video_watched" labelColor="text-white" label='Yes' type='radio' isActive />
            <Input name="video_watched" labelColor="text-white" label='No' type='radio' isActive />
          </div>
          <p className='font-bold text-xl ml-2 xl:text-base xl:ml-1.5 text-white sm:text-center'>I have received my VR Headset</p>
          <div className='flex gap-x-10'>
            <Input name="need_support" labelColor="text-white" label='Yes' type='radio' isActive />
            <Input name="need_support" labelColor="text-white" label='No' type='radio' isActive />
          </div>

          <OrangeButton className={` mt-4 flex  items-center justify-center duration-300 delay-1000 translate-x-0 opacity-100 hover:delay-0`}>
            {loading ? "SENDING" : "SUBMIT"}
            {loading && <svg aria-hidden="true" className="w-6 h-6 animate-spin ml-2" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#173658" />
            </svg>}
          </OrangeButton>
        </form>
      )}
    </div>
  );
};
