import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

export const MihaiNavigation = ({ isAbsolute = false }) => {
  return (
    <div className={classNames(`flex justify-between items-center z-50 uppercase w-full bg-black font-roboto px-40 py-8 ${isAbsolute ? 'absolute top-0 left-0 right-0' : ''}`,
      'lg:px-20 lg:py-6 sm:!px-5 !lg-py-2'
    )}>
      <p className='text-orange text-3xl font-bold sm:text-2xl sm:leading-none'><span className='text-white font-normal'>Mihai</span>Streza</p>
      <div className='flex gap-x-20 font-bold sm:hidden'>
        <Link className='text-white text-3xl duration-150 hover:text-orange' to="https://wondder.io/contact">Contact</Link>
      </div>
    </div>
  );
};
