import React, { useState, useRef, useEffect } from 'react';
import TeamPicTop from 'assets/images/Team-pic.png';
import TeamPicBottom from 'assets/images/team-picture.png';
import { AboutSection2, AboutSection3, AboutSection4 } from './components';
import { useScrollY } from "hooks";
import { useWindowDimensions } from "hooks";

export const AboutUs = () => {
  const blockRef = useRef();
  const teamPicRef = useRef();
  const scrollY = useScrollY();
  const [visibleGrayBlock, setVisibleGrayBlock] = useState(false);
  const [teamPicActive, setTeamPicActive] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (blockRef.current.offsetTop + window.innerHeight * 1.4 < scrollY) {
      visibleGrayBlock || setVisibleGrayBlock(true);
    } else {
      visibleGrayBlock && setVisibleGrayBlock(false);
    }

    if (teamPicRef.current.getBoundingClientRect().y < window.innerHeight * 0.7) setTeamPicActive(true);
  }, [scrollY, visibleGrayBlock]);

  return (
    <div className='flex flex-col bg-dark-blue overflow-hidden'>
      <section className={`relative pt-[46.3%] w-full overflow-hidden`}>
        <img className={`absolute top-0 left-0 w-full h-full object-cover`} src={TeamPicTop} />
      </section>
      <div ref={blockRef} className='flex flex-col relative'>
        {width > 900 && <div className={`fixed top-[5%] bg-gray rounded-full w-[95vw] h-[95vw] -right-1/3 ${visibleGrayBlock ? 'opacity-100' : 'opacity-0'}`} />}
        <AboutSection2 />
        <AboutSection3 />
        <AboutSection4 />
      </div>
      <section ref={teamPicRef} className='relative w-full pt-[66.5%] mt-32'>
        <img className={`absolute top-0 left-0 w-full h-full object-cover  duration-700 delay-100 ease-in-out ${teamPicActive ? 'translate-x-0 w-full' : 'w-0 translate-x-full'}`} src={TeamPicBottom} />
      </section>
    </div>
  );
};

