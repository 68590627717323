import React, { useEffect, useState, useRef } from 'react';
import SectionBg from 'assets/images/Damir-tech.png';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import { useIsMobile } from "hooks";

export const SolutionsSection1 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    if (mobile) {
      setActive(true);
    } else {
      isBlockVisibleOnScreen(sectionRef.current, setActive, [0, 1]);
    }
  }, [mobile]);

  return (
    <section ref={sectionRef} className='flex relative pt-[52%] overflow-hidden md:pt-[57%] sm:!pt-0'>
      <img className='absolute top-0 left-0 w-full h-full md:object-cover lg:object-[11.5%]  md:!object-[58%] sm:hidden brightness-50' src={SectionBg} alt="vr background" loading="lazy" />
      <div className={cn(
        'z-20 max-w-[42rem] flex flex-col items-start absolute top-[60%] -translate-y-1/2 right-28 text-right xl:max-w-[27rem] xl:right-16',
        'md:top-[unset] md:translate-y-0 md:bottom-10 md:!right-10 md:left-10 md:text-left',
        'sm:static sm:!max-w-[unset] sm:mt-20 sm:px-10'
      )}>
        <p className={`min-lg:text-6xl font-bold text-white min-md:text-4xl sm:text-5xl sm:mb-10 ${active ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>ENTER THE VR RING</p>
        <p className={`text-2xl font-semibold text-white text-left mt-10 xl:text-lg xl:mt-8 md:text-base md:!mt-6 md:!text-base  ${active ? 'duration-300 delay-100 translate-x-0  opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>The moment you step into the small VR ring a whole, vast world opens. At wondder we will give you the tools to learn from that world, in order to become a leader that you truly are.</p>
      </div>
    </section>
  );
};
