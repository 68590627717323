import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { isBlockVisibleOnScreen } from "helpers/utils";
import Puma from 'assets/images/puma-min.jpg';
import Adidas from 'assets/images/adidas-min.jpg';
import Airbus from 'assets/images/airbus-min.jpg';
import Accenture from 'assets/images/accenture-min.jpg';
import { Autoplay } from 'swiper/modules';
import { OrangeButton } from 'components';

export const HomeSection7 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActive, [0.4, 1]);
  }, []);

  return (
    <div ref={sectionRef} className='relative overflow-hidden'>
      <span className='absolute top-0 right-0 w-full h-full bg-blue-gradient-to-left z-10 pointer-events-none	md:hidden'></span>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}

      >
        <SwiperSlide className='relative w-full pt-[47%] md:pt-[170%]'><img className="absolute top-0 left-0 w-full h-full object-cover" src={Puma} alt='slider img' /></SwiperSlide>
        <SwiperSlide className='relative w-full pt-[47%] md:pt-[170%]'><img className="absolute top-0 left-0 w-full h-full object-cover" src={Adidas} alt='slider img' /></SwiperSlide>
        <SwiperSlide className='relative w-full pt-[47%] md:pt-[170%]'><img className="absolute top-0 left-0 w-full h-full object-cover" src={Airbus} alt='slider img' /></SwiperSlide>
        <SwiperSlide className='relative w-full pt-[47%] md:pt-[170%]'><img className="absolute top-0 left-0 w-full h-full object-cover" src={Accenture} alt='slider img' /></SwiperSlide>
      </Swiper>
      <div className='flex flex-col items-center absolute top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 z-20 xl:translate-y-1/2 md:w-full'>
        <p className={`text-4xl font-semibold text-white mb-14 whitespace-nowrap	duration-300 xl:text-2xl xl:mb-6 md:whitespace-normal	md:text-center md:w-full md:px-8 ${active ? ' translate-x-0 opacity-100' : ' translate-x-1/4 opacity-0'}`}>We work with top progressive thinkers.</p>
        <OrangeButton href="success-stories" className={active ? 'duration-300 delay-300 translate-x-0 opacity-100 hover:delay-0' : 'duration-300  translate-x-1/4 opacity-0'}>Learn more</OrangeButton>
      </div>
    </div>
  );
};
