export const emailJsPublicKey = 'X4944m-UcdHvT70px';
export const emailJsServiceId = 'service_pjfi6us';
export const emailJsTemplateIdOnboarding = 'template_w5wkwto';
export const emailJsTemplateIdContactForm = 'template_acpwttf';
export const AlessioHubspot = 'https://meetings-eu1.hubspot.com/meetings/alessio-dell-anna?uuid=bc6f9408-96dd-4b39-a714-eada7947251e';

export const clientCredentials = {
  'username': 'wondder',
  'password': 'wondderful'
};
