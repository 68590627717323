import React, { useState, useEffect } from 'react';
import FbIcon from 'assets/images/facebook.png';
import LinkedinIcon from 'assets/images/linkedin.png';
import InstaIcon from 'assets/images/instagram.png';
import { useIsMobile } from "hooks";

export const Impressum = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  return (
    <div className='flex flex-col bg-dark-blue px-20 py-36 lg:px-10 lg:py-24 sm:py-16 sm:px-12'>
      <div className='flex items-center px-20 md:px-0 sm:flex-col-reverse'>
        <div className='flex flex-col ml-40 lg:ml-20 sm:!ml-0'>
          <div className='flex flex-col text-white'>
            <h2 className={`text-4xl lg:text-2xl sm:!text-xl font-bold mb-12 lg:mb-8 sm:!mb-6 duration-500 delay-150 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>IMPRESSUM</h2>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-200 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>VaRc GmbH</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-300 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Seat of the company: Berlin</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-[400ms] ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Register Court: Amtsgericht Charlottenburg, HRB 213567 B</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-500 ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Company Address: Zähringerstraße 26, 10707 Berlin, DE</p>
            <p className={`text-xl lg:text-base sm:!text-sm font-normal mb-2 duration-500 delay-[600ms] ${isPageLoaded ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/2'}`}>Managing Director: Mihai Streza-Cirlan</p>
          </div>
          {!mobile && <div className='flex mt-8'>
            <a className={`mr-5 lg:mr-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 ' : '-translate-y-1/2 opacity-0'}`} href="https://www.linkedin.com/company/wondder/" target="_blank" rel="noreferrer" ><img className='w-16 h-16 lg:w-10 lg:h-10' src={LinkedinIcon} /></a>
            <a className={`mx-5 lg:mx-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 delay-150' : '-translate-y-1/2 opacity-0'}`} href="https://www.instagram.com/wondder.berlin/" target="_blank" rel="noreferrer" ><img className='w-16 h-16 lg:w-10 lg:h-10' src={InstaIcon} /></a>
            <a className={`ml-5 lg:ml-3 ${isPageLoaded ? 'translate-y-0 opacity-100 duration-300 delay-300' : '-translate-y-1/2 opacity-0'}`} href="https://www.facebook.com/wondder.berlin" target="_blank" rel="noreferrer" ><img className='w-16 h-16 lg:w-10 lg:h-10' src={FbIcon} /></a>
          </div>}
        </div>
      </div>
    </div>
  );
};
