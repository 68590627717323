import React, { useRef, useEffect, useState } from 'react';
import { OrangeButton, VideoPlayer } from 'components';
import { isBlockVisibleOnScreen } from "helpers/utils";

const DITO_VIMEO_VIDEO = "https://player.vimeo.com/video/915216156?h=c5376c5c42&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

export const HomeSection2 = () => {
  const sectionRef = useRef(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActive, [0.6, 1]);
  }, []);

  return (
    <div className='flex relative pt-[56.3%] overflow-hidden sm:pt-[111%]'>
      <VideoPlayer isHome className='absolute top-0 left-0 w-full h-full md:h-[unset] md:pt-[56.4%] !translate-x-0 !opacity-100' src={DITO_VIMEO_VIDEO} />
      <div ref={sectionRef} className={`max-w-[26rem] absolute bottom-20 left-1/2 text-center transition-all xl:max-w-72 ${active ? 'opacity-100 duration-300 -translate-x-1/2' : 'opacity-0 duration-300 -translate-x-3/4'} md:!max-w-[unset] md:w-full md:px-8`}>
        <p className='text-4xl font-bold text-white mb-6 xl:text-2xl'>Explore our trainings in Virtual Reality.</p>
        <OrangeButton href='/our-solutions'>Learn more</OrangeButton>
      </div>
    </div>
  );
};
