import React from 'react';

import { Link } from 'react-router-dom';
import WondderLogo from '../../assets/images/wondder-logo.svg';
import OrangeWondderLogo from '../../assets/images/wondder-logo-orange.svg';

export const Logo = ({ onClick, orange = false, className }) => {
  return (
    <Link onClick={onClick} to="/" className={`w-24 h-24 xl:w-16 xl:h-16 ${className}`}>
      <img src={orange ? OrangeWondderLogo : WondderLogo} alt="wondder logo" />
    </Link>
  );
};

