import React from 'react';
import { SolutionsSection1, SolutionsSection2, SolutionsSection3, SolutionsSection4, SolutionsSection5, SolutionsSection6, SolutionsSection7, SolutionsSection8 } from './components';

export const OurSolutions = () => {
  return (
    <div className='flex flex-col bg-dark-blue md:overflow-hidden'>
      <SolutionsSection1 />
      <SolutionsSection2 />
      <SolutionsSection3 />
      <SolutionsSection4 />
      <SolutionsSection5 />
      <SolutionsSection6 />
      <SolutionsSection7 />
      <SolutionsSection8 />
    </div>
  );
};
