export function isBlockVisibleOnScreen(element, setActiveFunc, activePoints) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: activePoints,
  };

  const callback = (entries) => {
    entries.forEach((entry) => {
      setActiveFunc(entry.isIntersecting);
    });
  };

  const observer = new IntersectionObserver(callback, options);

  if (element) observer.observe(element);
}

export function downloadPdf(filename){
  const pdfUrl = `/files/${filename}`;
  const link = document.createElement('a');
  link.href = pdfUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
