import React from 'react';
import { downloadPdf } from 'helpers/utils';
import WIWYA from 'assets/images/when_i_was_your_age.png';
import HALFCIRCLE from 'assets/images/half-circle.png';

export const WhenIWasYourAge = () => {
  return (
    <div className='flex flex-col items-center relative bg-dark-blue pt-40 pb-36 overflow-hidden'>
      <img className='w-[27.2%] min-w-80 mb-16 lg:mb-16 sm:px-14 sm:min-w-[unset] sm:w-full sm:!mb-8' src={WIWYA} />
      <button
        onClick={() => downloadPdf('When I Was Your Age - Facing Age Bias with VR.pdf')}
        className={`text-sm uppercase p-2.5 !leading-none bg-orange rounded-xl text-white duration-200	border-2 border-orange min-lg:hover:bg-white min-lg:hover:text-orange min-lg:!hover:delay-0 xl:text-base`}>
        DOWNLOAD THE DOCUMENT
      </button>
      <img className='absolute top-0 right-0 rotate-180 w-[39.4%] sm:w-[70%]' src={HALFCIRCLE} />
      <img className='absolute bottom-0 left-0 w-[39.4%] sm:w-[70%]' src={HALFCIRCLE} />
    </div>
  );
};
