import React from 'react';
import { SuccessStoriesSection2, SuccessStoriesSection3, SuccessStoriesSection4, SuccessStoriesSection5 } from './components';

export const SuccessStories = () => {
  return (
    <div className='flex flex-col bg-dark-blue sm:overflow-hidden'>
      <SuccessStoriesSection2 />
      <SuccessStoriesSection3 />
      <SuccessStoriesSection4 />
      <SuccessStoriesSection5 />
    </div>
  );
};
