import React, { useEffect, useState, useRef } from 'react';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import CircleArc from 'assets/images/circle-arc.png';
import { useIsMobile } from "hooks";

export const SolutionsSection4 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    if (mobile) {
      setActive(true);
    } else {
      isBlockVisibleOnScreen(sectionRef.current, setActive, [0.3, 1]);
    }
  }, [mobile]);

  return (
    <section id="dei-training" ref={sectionRef} className='flex relative mt-[24rem] md:mt-[16rem] sm:!mt-32'>
      <div className='flex relative justify-between items-center lg:w-full lg:mx-8 md:!mx-10 sm:flex-col'>
        <div className='relative w-[30vw] h-[30vw] flex flex-col items-center justify-center ml-[10vw] lg:ml-0 sm:w-[calc(100vw-80px)] sm:h-[calc(100vw-80px)]'>
          <img className={cn('absolute top-0 left-0 w-full h-full duration-200 -rotate-180',
            { 'rotate-0 delay-200': active }
          )} src={CircleArc} alt='circle arc' />
          <p className='text-white text-3xl z-20 text-center xl:text-xl'>50% Reduction <br /> in unconscious bias</p>
        </div>
        <div className='flex flex-col max-w-[32rem] ml-[13rem] min-xl:ml-[30rem] lg:ml-10 md:flex-1 sm:mt-16 sm:text-center sm:!ml-0'>
          <p className={`text-6xl font-bold text-white xl:text-4xl max-w-[95%] xl:mt-8 md:text-base md:!mt-6 md:text-right sm:!text-center ${active ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>DIVERSITY EQUITY INCLUSION</p>
          <p className={`text-base font-medium text-white text-left mt-10 xl:text-lg xl:mt-8 md:text-base md:!mt-6 md:text-right sm:!text-center  ${active ? 'duration-300 delay-100 translate-x-0  opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>VR training offers a powerful tool for implementing DEI strategies in the workplace by providing immersive, empathy-building experiences that help employees understand diverse perspectives, simulate real-world scenarios, and foster a more inclusive and understanding organizational culture.</p>
        </div>
      </div>
    </section>
  );
};
