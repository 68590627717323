import React from 'react';
import { WhiteButton } from '../WhiteButton';
import classNames from 'classnames';
import { useIsMobile } from "hooks";

import bg1 from 'assets/images/mihai-landing-page/blog/bg-1.png';
import bg2 from 'assets/images/mihai-landing-page/blog/bg-2.png';
import bg3 from 'assets/images/mihai-landing-page/blog/bg-3.png';

const blogData = [
  {
    image: bg1,
    title: "Embrace Age Diversity with Cutting-Edge VR Training Solutions",
    text: "In an era where the workforce spans multiple generations, age diversity has become a critical aspect of organizational success.",
    url: "#"
  },
  {
    image: bg2,
    title: "Far Apart, Close at Heart: 5 Tips for Keeping Your Remote Team Connected.",
    text: "Are you curious about the keys to successfully managing a remote team in today's increasingly digital workplace?",
    url: "#"
  },
  {
    image: bg3,
    title: "Mastering the Art of Feedback: Transform Your Communication and Motivate Your Team",
    text: "Effective feedback, when delivered well, can lead to remarkable improvements in team dynamics, productivity, and individual performance.",
    url: "#"
  }
];
export const BlogSection = () => {
  const isMobile = useIsMobile();
  return (
    <div className='flex flex-col items-center relative w-full py-36 overflow-hidden pointer-events-none lg:py-28 sm:!py-0 sm:overflow-hidden sm:max-h-40'>
      <p className={classNames('uppercase text-white font-anton text-5xl tracking-widest relative',
        'before:content-[""] before:absolute before:top-1/2 before:-translate-y-1 before:right-[calc(100%+40px)] before:bg-orange before:h-2 before:w-[50vw]',
        'after:content-[""] after:absolute after:top-1/2 after:-translate-y-1 after:left-[calc(100%+40px)] after:bg-orange after:h-2 after:w-[50vw]',
        'sm:hidden'
      )}>Wondder blog</p>
      <div className='flex gap-x-3 px-16 mt-28 max-w-7xl relative pb-4 lg:mt-20 sm:!mt-0'>
        <p className={classNames('font-anton text-white text-[152px] absolute w-full h-full top-0 left-0 uppercase backdrop-blur-md flex items-center justify-center',
          'lg:text-8xl', 
          'sm:!text-[0]')}>Coming soon!</p>
        {blogData.map((blog, k) => (
          <SingleBlog blog={blog} key={k} />
        ))}
      </div>
      {isMobile && <div className='flex flex-col items-center absolute top-1/2 -translate-y-1/2'>
        <p className='text-2xl text-white font-anton uppercase tracking-widest mb-2'>Wondder blog</p>
        <p className='text-4xl text-white font-anton uppercase tracking-widest'>Coming soon!</p>
      </div>}
    </div>
  );
};

const SingleBlog = ({ blog }) => {
  const { image, title, text, url } = blog;

  return (
    <div className='flex flex-col items-center flex-1'>
      <img className='w-full h-[16rem] object-cover' src={image} alt='featured image' />
      <div className='flex flex-col items-center text-white text-center mt-10 flex-1'>
        <p className='font-anton text-xl'>{title}</p>
        <p className='font-roboto text-xl tracking-wider my-12 flex-1'>{text}</p>
        <WhiteButton className='normal-case	' href={url}>Read the article</WhiteButton>
      </div>
    </div>
  );
};
