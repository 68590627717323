import React from 'react';
import Dots from 'assets/images/mihai-landing-page/black-gradient.png';
import MihaiTransform from 'assets/images/mihai-landing-page/mihai-transform.png';
import { WhiteButton } from '../index';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';
import { useIsMobile } from "hooks";

export const TransformingLeadership = () => {
  const isMobile = useIsMobile();

  return (
    <div className='flex relative w-full px-32 overflow-hidden lg:px-20 sm:flex-col-reverse sm:!px-10 sm:overflow-visible sm:pb-16'>
      <span className='bg-orange w-[60rem] h-[60rem] rounded-full absolute top-1/2 -translate-y-1/2 z-20 -left-[54rem] lg:-left-[57rem] sm:w-32 sm:h-32 sm:top-[2%] sm:!left-[95%] sm:-translate-x-full' />
      <div className={classNames('absolute top-1/2 left-40 -translate-y-1/2 mt-8 flex flex-col justify-start text-white text-6xl font-anton z-30 leading-relaxed	tracking-wider',
        'lg:text-5xl lg:leading-snug lg:left-20 lg:mt-0',
        'sm:!text-2xl sm:!leading-normal sm:items-center sm:text-center sm:static sm:translate-y-0 sm:!mt-12')}>
        <p >TRANSFORMING</p>
        <p>LEADERSHIP WITH</p>
        <p className='text-orange sm:border-b-2 sm:border-color-orange sm:w-full sm:pb-6'>TECHNOLOGY<span className='text-white'>.</span></p>
        <p className={classNames('max-w-[38rem]	text-white text-2xl font-roboto mb-4 mt-10','lg:text-xl lg:mt-8 lg:mb-0 lg:max-w-lg','sm:!text-base sm:!text-center')}>Building on my background in computer science, I introduce the transformative potential of technology, particularly virtual reality (VR). By 2030, a billion VR headsets will be in use globally, presenting an unprecedented opportunity to bring existing, effective transformational tools into the virtual realm.</p>
        <HashLink className='' to="/contact#book-a-demo">
          <WhiteButton className='self-start mt-16 lg:mt-10 sm:!mt-7'>Book a call</WhiteButton>
        </HashLink>
        {isMobile && 
          <>
            <a
              target='_blank'
              className='mt-7 font-roboto font-bold uppercase !leading-none bg-white text-black duration-200	border-2 border-white py-3 px-5 !text-xl !rounded-2xl'
              href="https://www.eventbrite.com/e/lead-your-company-into-the-future-with-vr-tickets-914616120437?aff=erelpanelorg&_gl=1*i2h7j6*_up*MQ..*_ga*MTM1OTE5MTc5NC4xNzE3MTU0MDEy*_ga_TQVES5V6SH*MTcxNzE1NDAxMi4xLjAuMTcxNzE1NDAxMi4wLjAuMA"
              rel="noreferrer">
              See Upcoming events
            </a>
            <WhiteButton className='mt-7' href="https://wondder.io/">Visit wondder</WhiteButton>
          </>
        }
      </div>
      <img className={`absolute top-0 left-0 w-full h-full object-cover sm:top-[unset] sm:bottom-0 sm:h-[89%]`} src={Dots} alt="dots" />
      <img className={`w-[60%] z-10 py-16 rounded-[3rem] ml-auto sm:w-[90%] sm:ml-0 sm:py-0 sm:self-center sm:z-30`} src={MihaiTransform} alt="transform leadership with technology" />

    </div>
  );
};
